import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentList from "../../components/Payment/PaymentList";
import PaymentTotal from "../../components/Payment/PaymentTotal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import HorizontalPadding from "../../components/HorizontalPadding";
import ShipmentOwner from "../../components/Shipment/ShipmentOwner";
import { currencyExchange, useCurrencyExchanges } from "../../utils";
import ShipmentTable, {
  ShipmentField,
} from "../../components/Shipment/ShipmentTable";
import { UserInterface } from "../../interfaces/UserInterface";
import {
  consultBC,
  sendC2PBC,
  sendB2PBC,
  payMerchant,
  savePayment,
  getDocument,
  saveDocument,
  alertService,
  saveProofOfPayment,
  updateDocumentStatus,
  getAccount,
  loaderService,
  updatePayment,
  annulationMerchant,
  getVtid,
  mercantilMobilePaymentSearch,
  mercantilTransferSearch,
  mercantilC2P,
  mercantilChange,
  ReintegrationRequest,
  deleteFileDO,
} from "../../services";
import {
  clearPickUp,
  setPickupOwner,
  setPickupTaxes,
  setPickupDocument,
  setPickupShipments,
} from "../../store/slices";
import { BackButton, PrimaryButton } from "../../components/Buttons";
import {
  TaxInterface,
  DocumentStatus,
  PaymentInterface,
  DocumentInterface,
  PaymentMethodEnum,
  DocumentType,
  PaymentStatusEnum,
  BankAccountInterface,
  PaymentMode,
} from "../../interfaces";
import { PickupModal } from "../../components/Shipment/PickupModal";
import moment from "moment";
import Modal from "../../components/Modal";
import Confetti from "react-confetti";
import classNames from "classnames";
import { PaymentCallbackContext } from "../../contexts/PaymentCallbackContext";
import { ChangePaymentModeModal } from "../../components/Shipment/ChangePaymentModeModal";

const Pickup: FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = loaderService.useIsLoading();
  const [vtid, setVtid] = useState<string>();
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const exchanges = useCurrencyExchanges();
  const [podSuccess, setPodSuccess] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const taxes = useAppSelector((state) => state.pickup.taxes);
  const owner = useAppSelector((state) => state.pickup.owner);
  const [openCoupon50GIF, setOpenCoupon50GIF] = useState(false);
  const coupon = useAppSelector((state) => state.pickup.coupon);
  const [openCoupon100GIF, setOpenCoupon100GIF] = useState(false);
  const userBU = useAppSelector((state) => state.user.businessUnit);
  const document = useAppSelector((state) => state.pickup.document);
  const payments = useAppSelector((state) => state.pickup.payments);
  const buUser = useAppSelector((state) => state.user.businessUnit);
  const shipments = useAppSelector((state) => state.pickup.shipments);
  const [openDeliverModal, setOpenDeliverModal] = useState(false);
  const [openChangePaymentMode, setOpenChangePaymentMode] = useState(false);

  const items = useMemo(() => {
    return shipments
      .map((shipment) => shipment?.items)
      .reduce((acc, curr) => [...acc, ...curr], []);
  }, [shipments]);

  const subTotal = useMemo(() => {
    return +shipments
      .reduce((acc, shipment) => {
        return (
          acc +
          (shipment?.items.reduce((acc, item) => acc + item.rate.value, 0) ?? 0)
        );
      }, 0)
      .toFixed(2);
  }, [shipments]);

  const ipostel = useMemo(() => {
    return (
      document?.taxes.find((tax) => tax.name === "Ipostel")?.value ??
      shipments.reduce((acc, shipment) => {
        return (
          acc + shipment.items.reduce((acc, item) => acc + item.rate.ipostel, 0)
        );
      }, 0)
    );
  }, [shipments, document?.taxes]);

  const iva = useMemo(() => {
    return (
      document?.taxes.find((tax) => tax.name === "IVA")?.value ??
      shipments.reduce((acc, shipment) => {
        return (
          acc + shipment.items.reduce((acc, item) => acc + item.rate.iva, 0)
        );
      }, 0)
    );
  }, [shipments, document?.taxes]);

  const total = useMemo(() => {
    return (
      document?.total ??
      +(
        subTotal +
        taxes?.reduce((acc, tax) => {
          if (tax.type === "percentage") {
            return acc + subTotal * (tax.value / 100);
          }
          return acc + tax.value;
        }, 0)
      ).toFixed(2)
    );
  }, [subTotal, taxes, document?.total]);

  const remaining = useMemo(() => {
    //return document?.baseBalanceAmount ?? to do this we need to get the updated document from DB
    return +(
      total -
      payments
        .filter(
          (p) =>
            p.status !== PaymentStatusEnum.CANCELED &&
            p.status !== PaymentStatusEnum.REJECT
        )
        .reduce((acc, payment) => acc + payment.amount, 0)
    ).toFixed(2);
  }, [total, payments]);

  const paymentMode = useMemo(() => {
    return shipments[0]?.paymentMode;
  }, [shipments]);

  const onRegret = () => {
    dispatch(clearPickUp());
    navigate("/shipments");
  };

  const onSave = async () => {
    // If there are a document, don't save it again
    if (!!document?.documentID) return;

    const status = total < 0.01 ? DocumentStatus.PAID : DocumentStatus.PENDING;

    // Save document
    const documentData: DocumentInterface = {
      documentID: "",
      accountOwner: owner,
      paymentMode: paymentMode!,
      subTotal,
      total,
      status,
      shipments,
      taxes,
      payments: [],
      printed: false,
      hasRetention: false, //document.payments.some((p) => p.isRetention)
      igtfAmount: 0,
      balanceAmount: total,
      documentType: DocumentType.ORDER,
    };
    const response = await saveDocument(
      documentData,
      buUser?.code,
      user ?? undefined,
      owner,
      coupon?.couponType.couponDescription,
      applicationID
    );
    if (!!response.didError || !response.model?.id_solicitud) {
      alertService.error(`Error al guardar la orden`);
      loaderService.stop();
      return;
    }

    const newDocument = {
      ...documentData,
      documentID: response.model.id_solicitud,
    };

    const documentType =
      status === DocumentStatus.PAID ||
      paymentMode === PaymentMode.DEST_BOXOFFICE_CREDIT
        ? DocumentType.INVOICE
        : DocumentType.ORDER;

    if (documentType === DocumentType.INVOICE) {
      const response = await updateDocumentStatus(
        newDocument.documentID,
        status,
        documentType,
        user ?? undefined,
        status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
        DocumentType.ORDER
      );

      if (!!response.didError) {
        alertService.error(
          "Hubo un error actualizando el estado de la orden",
          response.errorMessage
        );
      } else {
        newDocument.status = status;
        newDocument.documentType = documentType;
      }
    }

    loaderService.stop();

    return await updateDocument(newDocument);
  };

  const updateDocument = async (document?: DocumentInterface) => {
    if (!document?.documentID) {
      return;
    }

    loaderService.start();

    const documentResponse = await getDocument(document.documentID);
    if (!documentResponse) {
      loaderService.stop();
      return undefined;
    }

    let updatedDocument = {
      ...document!,
      documentNumber: documentResponse.documentNumber,
      payments: documentResponse.payments,
      total: documentResponse.total,
      status: documentResponse.status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    loaderService.stop();
    dispatch(setPickupDocument(updatedDocument));

    return updatedDocument;
  };

  const onPay = async (
    payment: PaymentInterface,
    avoidVerifications?: boolean
  ) => {
    const paymentVerified =
      avoidVerifications || user?.roleName === "Superadministrador";
    let result = {
      error: false,
      message: "",
    };

    loaderService.start();
    let savedDocument;

    if (document?.documentID) {
      savedDocument = document;
    } else {
      savedDocument = await onSave();
      if (!savedDocument) {
        loaderService.stop();
        return {
          error: true,
          message: "Error al crear el documento",
        };
      }
    }

    if (
      paymentVerified &&
      payment.paymentMethod.paymentMethodID !== PaymentMethodEnum.MERCHANT
    ) {
      payment.paymentMethod = {
        ...payment.paymentMethod,
        paymentMethodID: Math.abs(payment.paymentMethod.paymentMethodID),
      };
    } else if (
      paymentVerified &&
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.MERCHANT
    ) {
      payment.paymentMethod = {
        ...payment.paymentMethod,
        paymentMethodID: PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO,
      };
    }

    // Merchant payment
    else if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.MERCHANT ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_P2C ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO
    ) {
      let ci = payment.clientIdentifier!;
      if (isNaN(parseInt(ci[0]))) {
        ci = ci.slice(1);
      }

      payment.paymentMethod.paymentMethodID =
        PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO;
      payment.paymentMethod.bankID = 27;
      payment.paymentMethod.currencyID = 1;

      const res = await payMerchant(
        currencyExchange(payment.amount, exchanges, "BS"),
        ci
      );

      if (!res || res.codRespuesta !== "00") {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.mensajeRespuesta ?? "Error al procesar el pago",
        };
      } else {
        if (res.tipoProducto !== "P2C") {
          payment.paymentMethod.paymentMethodID =
            PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO;
          payment.paymentMethod.bankID = 27;
          payment.reference = `${res?.numSeq ?? ""}:${
            res?.terminalVirtual ?? ""
          }:${res?.numeroAutorizacion}`;
          payment.status = PaymentStatusEnum.PENDING;
        } else {
          payment.paymentMethod.paymentMethodID =
            PaymentMethodEnum.PAGO_MERCHANT_P2C;
          payment.paymentMethod.bankID = 4;
          payment.reference = res?.numeroReferencia ?? "";
        }
      }
    }

    // P2C payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_P2C &&
      payment.destBankID === 6
    ) {
      const res = await consultBC(
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.phone!,
        payment.reference!,
        "PM",
        ""
      );

      if (!res?.success) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: !!res?.message
            ? res.message.code + " " + res.message.message
            : "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // P2C payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_P2C &&
      payment.destBankID === 4
    ) {
      const res = await mercantilMobilePaymentSearch(
        payment.phone!,
        moment(payment.paymentDate).toDate(),
        payment.reference!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.attempts ?? 1
      );

      if (res?.didError || res.model?.transaction_list?.length === 0) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else if (
        res.model?.transaction_list &&
        res.model?.transaction_list?.length > 0
      ) {
        payment.reference = res?.model?.transaction_list[0].payment_reference;
      }
    }

    // TRF payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
      payment.destBankID === 6
    ) {
      const res = await consultBC(
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        "",
        payment.reference!,
        "TRF",
        ""
      );

      if (!res?.success) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: !!res?.message
            ? res.message.code + " " + res.message.message
            : "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // TRF payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
      payment.destBankID === 4
    ) {
      const res = await mercantilTransferSearch(
        payment.clientIdentifier!,
        moment(payment.paymentDate).toDate(),
        payment.bank!,
        payment.reference!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.attempts ?? 1
      );

      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.errorList && res.model?.errorList.length > 0) {
          errorMessage = res.model?.errorList
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      }
      if (
        res.model?.transferSearchList &&
        res.model?.transferSearchList?.length > 0
      ) {
        if (res.model?.transferSearchList[0].trxStatus === "R") {
          payment.status = PaymentStatusEnum.REJECT;
          result = {
            error: true,
            message: "La transferencia fue rechazada por el banco",
          };
        } else if (
          res.model?.transferSearchList[0].previouslySearched === "S"
        ) {
          payment.status = PaymentStatusEnum.REJECT;
          result = {
            error: true,
            message: "El pago ya fue verificado previamente",
          };
        }
      }
    }

    // C2P payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_C2P &&
      payment.destBankID === 6
    ) {
      const res = await sendC2PBC(
        payment.bank!,
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.phone!,
        payment.otp
      );

      if (res?.codigoError !== 0) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.descripcionError ?? "Error al procesar el pago",
        };
      }
      payment.reference = res?.secuencial.toString();
    }

    // C2P payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_C2P &&
      payment.destBankID === 4
    ) {
      const res = await mercantilC2P(
        payment.phone!,
        payment.clientIdentifier!,
        payment.otp!,
        savedDocument?.documentID!.split("-")[0]!,
        payment.amount,
        payment.bank!
      );
      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else {
        payment.reference = `${res?.model?.transaction_c2p_response?.payment_reference}:${res?.model?.transaction_c2p_response?.invoice_number}`;
      }
    }

    // POS payment
    else if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.PUNTO_DE_VENTA
    ) {
      payment.reference = `${payment.affiliateNumber}:${payment.batchNumber}`;
    }

    // B2P change payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 6
    ) {
      const res = await sendB2PBC(
        payment.bank!,
        payment.clientIdentifier!,
        payment.amount,
        payment.phone!,
        document ? `Asociado a orden #${document.documentID}` : "Pago 1 B2P",
        "Factura"
      );

      if (res?.codigoError !== 0) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.descripcionError ?? "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // B2P change payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 4
    ) {
      const res = await mercantilChange(
        payment.phone!,
        payment.clientIdentifier!,
        document?.documentID!.split("-")[0]!,
        payment.amount,
        payment.bank!
      );
      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else {
        payment.reference = `${res?.model?.transaction_c2p_response?.payment_reference}:${res?.model?.transaction_c2p_response?.invoice_number}`;
      }
    }

    // Reintegration
    if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.REINTEGRO &&
      payment.status === PaymentStatusEnum.PENDING
    ) {
      const res = await ReintegrationRequest(
        user?.userName!,
        userBU!,
        document!,
        payment.amount,
        document?.accountOwner!
      );

      payment.paymentMethod.bankAccountID = undefined;

      if (res.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res.errorMessage,
        };
      } else {
        payment.status = PaymentStatusEnum.PENDING;
      }
    }

    // verifies that the payment was not verified before
    let verified = false;
    if (
      payment.status !== PaymentStatusEnum.REJECT &&
      payment.paymentMethod.paymentMethodID !== PaymentMethodEnum.EFECTIVO &&
      !paymentVerified
    )
      document?.payments.forEach((p) => {
        if (
          p.reference === payment.reference &&
          p.reference !== "" &&
          p.status !== PaymentStatusEnum.REJECT
        ) {
          verified = true;
        }
      });

    if (verified) {
      payment.status = PaymentStatusEnum.REJECT;
      result = {
        error: true,
        message: "El pago ya fue verificado previamente",
      };
    }

    // Convert amount to negative if change
    if (
      [
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
        PaymentMethodEnum.VUELTO_EFECTIVO,
        PaymentMethodEnum.REINTEGRO,
      ].includes(payment.paymentMethod.paymentMethodID)
    ) {
      payment.amount = -payment.amount;
      payment.paymentAmount = -(payment.paymentAmount ?? 0);
    }

    // Change currency
    let currencyId = payment.paymentMethod.currencyID;

    const response = await savePayment(
      payment,
      savedDocument!,
      userBU?.code,
      user ?? undefined,
      currencyId
    );
    if (!!response.didError) {
      loaderService.stop();
      alertService.error("Error al guardar el pago", "Intente de nuevo");
      return {
        error: true,
        message: "Error al guardar el pago",
      };
    }

    // Update document
    const oldBalance =
      savedDocument?.payments
        .filter(
          (p) =>
            p.status !== PaymentStatusEnum.CANCELED &&
            p.status !== PaymentStatusEnum.REJECT
        )
        .reduce((acc, payment) => acc + payment.amount, 0) ?? 0;
    const updatedDocument = await updateDocument(savedDocument);
    const newBalance =
      updatedDocument?.payments
        .filter(
          (p) =>
            p.status !== PaymentStatusEnum.CANCELED &&
            p.status !== PaymentStatusEnum.REJECT
        )
        .reduce((acc, payment) => acc + payment.amount, 0) ?? 0;

    if (
      oldBalance < (updatedDocument?.total ?? 0) &&
      newBalance >= (updatedDocument?.total ?? 0)
    ) {
      setPodSuccess(true);
    }

    loaderService.stop();

    return result;
  };

  const onSaveProofOfPayment = async (
    file: File,
    retention: PaymentInterface,
    user: UserInterface,
    statusID: number,
    reference?: string
  ) => {
    const saved = await saveProofOfPayment(
      file,
      retention,
      user,
      statusID,
      reference,
      undefined,
      document?.documentID
    );

    if (!saved) return;

    const newPayments = payments.map((payment) => {
      if (payment !== retention) return payment;

      return {
        ...payment,
        proofOfPayment: file.name,
      };
    });

    const updatedDocument: DocumentInterface = {
      ...document!,
      payments: newPayments,
    };
    const response = await saveDocument(
      updatedDocument,
      buUser?.code,
      user ?? undefined,
      undefined,
      undefined,
      applicationID
    );
    if (!!response.didError) {
      alertService.error("Error al guardar la orden");
      return;
    }
    dispatch(
      setPickupDocument({
        ...updatedDocument,
        documentID: response.model!.id_solicitud,
      })
    );
  };

  const onDeleteProofOfPayment = async (retention: PaymentInterface) => {
    loaderService.start();

    const response = await deleteFileDO(retention.proofOfPayment!);

    if (response.didError || !response.model) {
      loaderService.stop();
      alertService.error(
        "Error al eliminar el comprobante de pago",
        response.errorMessage
      );
      return;
    }

    alertService.success("El comprobante se eliminó exitosamente");

    const newPayments = payments.map((payment) => {
      if (payment !== retention) return payment;

      return {
        ...payment,
        proofOfPayment: undefined,
      };
    });

    const update = await updatePayment({
      documentID: document?.documentID ?? retention.documentID!,
      paymentDetailID: retention.paymentDetailID!,
      statusID: PaymentStatusEnum.PENDING,
      user: user!,
    });

    if (update.didError) {
      alertService.error(
        "Error al eliminar el comprobante",
        update.errorMessage
      );
      loaderService.stop();
      return;
    }

    const updatedDocument: DocumentInterface = {
      ...document!,
      payments: newPayments,
    };

    loaderService.stop();
    dispatch(
      setPickupDocument({
        ...updatedDocument,
      })
    );
  };

  const onCancelRetention = async (retention: PaymentInterface) => {
    loaderService.start();
    const response = await updatePayment({
      documentID: document?.documentID ?? retention.documentID!,
      paymentDetailID: retention.paymentDetailID!,
      statusID: PaymentStatusEnum.CANCELED,
      user: user!,
    });

    if (!!response.didError) {
      alertService.error("Error al cancelar la retención");
      loaderService.stop();
      return;
    }

    await updateDocument(document);

    loaderService.stop();
  };

  const onCancelPayment = async (transaction: PaymentInterface) => {
    loaderService.start();

    if (vtid === null) {
      alertService.error("No se pudo obtener el VTID");
      loaderService.stop();
      return;
    }

    if (transaction.reference) {
      const paymentVtid = transaction.reference.split(":")[1];

      if (paymentVtid === vtid) {
        const numSeq = transaction.reference.split(":")[0];
        const res = await annulationMerchant(numSeq);

        if (res !== null && res.codRespuesta === "00") {
          const response = await updatePayment({
            documentID: document?.documentID ?? transaction.documentID!,
            paymentDetailID: transaction.paymentDetailID!,
            statusID: PaymentStatusEnum.CANCELED,
            user: user!,
          });

          if (response.didError) {
            alertService.error("Error al cancelar el pago");
            loaderService.stop();
            return;
          }
          alertService.success("El pago se anuló exitosamente");

          const documentResponse = await getDocument(document!.documentID);
          if (!documentResponse) {
            alertService.error("Error al obtener la orden");
            loaderService.stop();
            return;
          }

          const totalPaid = documentResponse.payments
            .filter(
              (p) =>
                p.status !== PaymentStatusEnum.CANCELED &&
                p.status !== PaymentStatusEnum.REJECT
            )
            .reduce((acc, payment) => acc + payment.amount, 0);
          const remaining = total - totalPaid;
          const status =
            (documentResponse.balanceAmount ?? remaining) < 0.01
              ? DocumentStatus.PAID
              : DocumentStatus.PARTIAL_PAID;

          let updatedDocument = {
            ...document!,
            payments: documentResponse.payments,
            total: documentResponse.total ?? total,
            status,
            balanceAmount: documentResponse.balanceAmount,
            baseBalanceAmount: documentResponse.baseBalanceAmount,
          };

          // Update document status
          if (document?.status !== status) {
            const response = await updateDocumentStatus(
              updatedDocument.documentID,
              status,
              undefined,
              user ?? undefined,
              status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
              updatedDocument.documentType
            );
            if (!!response.didError) {
              alertService.error(
                "Hubo un error actualizando el estado de la orden"
              );
            } else {
              updatedDocument.status = status;
            }
          }

          loaderService.stop();
          dispatch(setPickupDocument(updatedDocument));
          return;
        }
        alertService.error("No se pudo anular el pago");
        loaderService.stop();
        return;
      }
      alertService.error("Este pago no se proceso con el terminal actual");
      loaderService.stop();
      return;
    }
    alertService.error(
      "La operación no tiene terminal asociado, no se puede anular"
    );
    loaderService.stop();
  };

  const onApproveReintegration = async (
    transaction: PaymentInterface,
    bankAccount?: BankAccountInterface
  ) => {
    loaderService.start();
    const response = await updatePayment({
      documentID: document!.documentID,
      paymentDetailID: transaction.paymentDetailID!,
      bankAccountID: bankAccount?.bankAccountID,
      sourceBankID: bankAccount?.bankID,
      statusID: PaymentStatusEnum.APPROVE,
      user: user!,
    });

    if (response.didError) {
      alertService.error("No se pudo aprobar el reintegro");
      loaderService.stop();
      return;
    }
    alertService.success("Reintegro aprobado con éxito");
    const documentResponse = await getDocument(document!.documentID);
    if (!documentResponse) {
      alertService.error("Error al obtener la orden");
      loaderService.stop();
      return;
    }

    const totalPaid = documentResponse.payments
      .filter(
        (p) =>
          p.status !== PaymentStatusEnum.CANCELED &&
          p.status !== PaymentStatusEnum.REJECT
      )
      .reduce((acc, payment) => acc + payment.amount, 0);
    const remaining = total - totalPaid;
    const status =
      (documentResponse.balanceAmount ?? remaining) < 0.01
        ? DocumentStatus.PAID
        : DocumentStatus.PARTIAL_PAID;

    let updatedDocument = {
      ...document!,
      payments: documentResponse.payments,
      total: documentResponse.total ?? total,
      status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    // Update document status
    if (document?.status !== status) {
      const response = await updateDocumentStatus(
        updatedDocument.documentID,
        status,
        undefined,
        user ?? undefined,
        status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
        updatedDocument.documentType
      );
      if (response.didError) {
        alertService.error(
          "Hubo un error actualizando el estado de la orden",
          response.errorMessage
        );
      } else {
        updatedDocument.status = status;
      }
    }

    loaderService.stop();
    dispatch(setPickupDocument(updatedDocument));
  };

  const onChangePaymentMode = async () => {
    setOpenChangePaymentMode(true);
  };

  useEffect(() => {
    const taxes: TaxInterface[] = [
      {
        name: "IVA",
        value: iva,
        type: "fixed",
      },
      {
        name: "Ipostel",
        value: ipostel,
        type: "fixed",
      },
    ];

    if (!!document?.igtfAmount) {
      taxes.push({
        name: "IGTF",
        value: document.igtfAmount,
        type: "fixed",
      });
    }

    dispatch(setPickupTaxes(taxes));
  }, [ipostel, iva, document, dispatch]);

  useEffect(() => {
    const updateAccount = async (id: string) => {
      return await getAccount(id);
    };

    const updateAccounts = async () => {
      const newShipments = await Promise.all(
        shipments.map(async (shipment) => {
          const shipper = (await updateAccount(shipment.shipper?.id ?? ""))!;
          const consignee = (await updateAccount(
            shipment.consignee?.id ?? ""
          ))!;
          return {
            ...shipment,
            shipper,
            consignee,
          };
        })
      );

      dispatch(setPickupShipments(newShipments));
    };

    updateAccounts();

    // Do not add shipments to the dependency list to avoid an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Get vtid
  useEffect(() => {
    const buCode = buUser?.code;
    if (!buCode) return;

    const saveVtid = async () => {
      const buConfigResponse = await getVtid(buCode);
      if (buConfigResponse.didError) return;

      const vtid = buConfigResponse.model?.vtid;
      if (!vtid) return;
      setVtid(vtid);
    };

    saveVtid();
  }, [buUser?.code]);

  useEffect(() => {
    if (coupon) {
      const audio = new Audio("/audio/TEALCA Sonido Promoción.mp3");

      audio.volume = 0;
      audio.play();

      const is50 = coupon.couponValue === 50;
      const is100 = coupon.couponValue === 100;
      if (is100) {
        setOpenCoupon100GIF(true);
      } else if (is50) {
        setOpenCoupon50GIF(true);
      }

      let volume = 0;
      let peak = false;
      const interval = setInterval(() => {
        if (!peak && volume < 1) {
          volume += 0.025;
          audio.volume = Math.min(1, volume);
        } else if (!peak) {
          peak = true;
        } else if (peak && volume > 0) {
          volume -= 0.025;
          audio.volume = Math.max(0, volume);
        } else {
          clearInterval(interval);
        }
      }, 100);
    }
  }, [coupon]);

  useEffect(() => {
    updateDocument(document);
  }, []);

  return (
    <main className="lg:pl-72 pb-32">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <div>
          <header className="ml-4 text-2xl font-bold text-gray-700 ">
            Entregar Paquetes
          </header>
        </div>
      </div>

      <HorizontalPadding paddingTop>
        <div className="flex flex-1 flex-col gap-8">
          {/* Header */}
          {(!document || document?.status === DocumentStatus.PENDING) && (
            <div className="flex w-full">
              <BackButton handleOnRegret={onRegret} />
            </div>
          )}
          {/* Show invoice owner */}
          <ShipmentOwner
            owner={owner}
            onSelectOwner={(owner) => dispatch(setPickupOwner(owner))}
          />

          {/* Show shipments resumen */}
          <div className={"pr-1"} style={{ flex: 1 }}>
            <ShipmentTable
              fields={[
                ShipmentField.ID,
                ShipmentField.SHIPPER,
                ShipmentField.BU_SOURCE,
                ShipmentField.CONSIGNEE,
                ShipmentField.STATUS,
                ShipmentField.AMOUNT,
              ]}
              showDetails
              shipments={shipments.map((s) => s!)}
            />
          </div>

          {/* Show totals */}
          <PaymentTotal
            taxes={taxes}
            subTotal={subTotal}
            remaining={remaining}
            paymentMode={paymentMode}
            total={document?.total}
            onChangePaymentMode={onChangePaymentMode}
            allowPaymentModeChange={!document}
          />

          {/* Show payments */}
          {!!owner?.fiscalAddress ? (
            <PaymentCallbackContext.Provider
              value={{
                onFinishPayment: () => podSuccess && setOpenDeliverModal(true),
              }}
            >
              <PaymentList
                editable
                items={items}
                payments={payments}
                remaining={remaining}
                document={document}
                owner={owner}
                paymentMode={document?.paymentMode ?? shipments[0].paymentMode}
                onPay={onPay}
                onCancelRetention={onCancelRetention}
                onCancelPayment={onCancelPayment}
                onApproveReintegration={onApproveReintegration}
                onSaveProofOfPayment={onSaveProofOfPayment}
                onDeleteProofOfPayment={onDeleteProofOfPayment}
              />
            </PaymentCallbackContext.Provider>
          ) : (
            <div className="flex flex-col gap-4 bg-white rounded-lg border px-8 pb-6 pt-4">
              <p className="text-gray-400">
                Se necesita conocer la dirección fiscal de la persona a facturar
                para poder agregar pagos
              </p>
            </div>
          )}

          {(Math.abs(remaining) < 0.01 ||
            paymentMode === PaymentMode.DEST_BOXOFFICE_CREDIT) && (
            <div className="flex w-full justify-end">
              <PrimaryButton
                className="w-40"
                disabled={loading}
                onClick={async () => {
                  loaderService.start();
                  await onSave();
                  loaderService.stop();

                  setOpenDeliverModal(true);
                }}
              >
                FINALIZAR
              </PrimaryButton>
            </div>
          )}
        </div>
      </HorizontalPadding>

      <PickupModal
        openModal={openDeliverModal}
        setOpenModal={setOpenDeliverModal}
        shipments={shipments}
        consigneeName={shipments[0].consignee.accountFullName}
        consigneeId={shipments[0].consignee.identificationNumber}
        shipmentNumbers={shipments.map((s) => s?.shipmentNumber ?? "")}
        onSubmit={() => navigate(`/documents/${document?.documentID}`)}
      />

      {/* Coupon video modal */}
      <Modal
        openModal={openCoupon100GIF}
        setOpenModal={setOpenCoupon100GIF}
        className="!p-0 overflow-hidden"
      >
        <img src="/video/Coupon100.gif" alt="Promoción" />
      </Modal>

      <Modal
        openModal={openCoupon50GIF}
        setOpenModal={setOpenCoupon50GIF}
        className="!p-0 overflow-hidden"
      >
        <img src="/video/Coupon50.gif" alt="Promoción" />
      </Modal>

      <ChangePaymentModeModal
        shipments={shipments}
        document={document}
        open={openChangePaymentMode}
        setOpen={setOpenChangePaymentMode}
      />

      <Confetti
        className={classNames(
          !openCoupon100GIF && !openCoupon50GIF && "hidden"
        )}
      />
    </main>
  );
};

export default Pickup;
