import { ShipmentGuideModel } from "../interfaces";
// import { useAppSelector } from "../store/hooks";

export const ProcessBarcode = (BarCode: string) => {
  // const user = useAppSelector((state) => state.user);

  let BARCODE_SIZE = 30;
  let REGEX_BUCODE = /\b0+/g;
  return new Promise((resolve, rejects) => {
    try {
      let shipment: ShipmentGuideModel = {} as ShipmentGuideModel;
      if (BarCode.length === BARCODE_SIZE) {
        shipment.serviceCode = parseInt(BarCode.substring(0, 2));
        shipment.sourceBUCode = (
          isNaN(BarCode.substring(2, 7) as any)
            ? BarCode.substring(2, 7).replace(REGEX_BUCODE, "")
            : parseInt(BarCode.substring(2, 7))
        ).toString();
        shipment.shipmentNumber = parseInt(BarCode.substring(7, 15)).toString();
        shipment.pieceNumber = parseInt(BarCode.substring(15, 18));
        shipment.locationDestinationCode = BarCode.substring(18, 21);
        shipment.totalPiece = parseInt(BarCode.substring(21, 24));
        shipment.totalWeigth = parseFloat(
          `${BarCode.substring(24, 27)}.${BarCode.substring(27, 30)}`
        );
        shipment.barCode = BarCode;
        resolve(shipment);
      }
      throw new Error(
        "El código de barra no pertenece a una enumeración de Tealca."
      );
    } catch (error) {
      rejects(error);
    }
  });
};
