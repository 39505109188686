// import { useState } from "react";
// import { ManifestHeaderInterface } from "../../interfaces/ManifestInterface";
// import { FormCheckbox, FormTextInput } from "../FormFields";
// import moment from "moment";

// interface manifestHeaderTableProps {
//   data: ManifestHeaderInterface[] | undefined;
//   onRowClick: (manifest: ManifestHeaderInterface) => void;
//   Isfilter?: boolean | true;
// }

// const ManifestHeaderTable: React.FC<manifestHeaderTableProps> = ({
//   data,
//   onRowClick,
//   Isfilter,
// }) => {
//   const [tableData, setTableData] = useState(data);
//   const [filterText, setFilterText] = useState("");

//   const filteredData = data?.filter(
//     (row) =>
//       row?.masterShipmentHeaderCode?.includes(filterText) ||
//       row?.transportModelName?.includes(filterText) ||
//       row?.transportPlate?.includes(filterText) ||
//       row?.drivers?.toUpperCase().includes(filterText.toUpperCase())
//   );

//   return (
//     <div className="w-full">
//       {data?.length === 0 ? (
//         <p className="text-gray-400 text-center">
//           No hay manifiestos en esta lista
//         </p>
//       ) : (
//         <>
//           <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
//             {Isfilter && (
//               <div className="w-full sm:w-2/5 pl-2">
//                 <label className="block text-m font-medium leading-6 text-gray-900 mb-2 truncate">
//                   Filtrar
//                 </label>
//                 <FormTextInput
//                   type="text"
//                   placeholder="Por número de manifiesto, vehículo o conductor"
//                   value={filterText}
//                   onChange={(e) => setFilterText(e.target.value)}
//                 />
//               </div>
//             )}
//           </div>

//           <div className="overflow-x-auto pl-2">
//             <table className="table-auto w-full">
//               <thead>
//                 <tr className="text-center">
//                   {/* Item,Guía,Origen,Servicio,Pieza,Peso,Destino,Dias Activas,Estatus */}
//                   <th className="px-4 py-2 font-semibold text-xs">ITEM</th>

//                   <th className="px-4 py-2 font-semibold text-xs">
//                     MANIFIESTO
//                   </th>

//                   <th className="px-4 py-2 font-semibold text-xs">ORIGEN</th>

//                   <th className="px-4 py-2 font-semibold text-xs">DESTINO</th>

//                   <th className="px-4 py-2 font-semibold text-xs">
//                     FECHA CREACIÓN
//                   </th>

//                   <th className="px-4 py-2 font-semibold text-xs">
//                     FECHA CIERRE
//                   </th>

//                   <th className="px-4 py-2 font-semibold text-xs">VEHÍCULO</th>

//                   <th className="px-4 py-2 font-semibold text-xs">CONDUCTOR</th>

//                   <th className="px-4 py-2 font-semibold text-xs">ESTATUS</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredData?.map((row, index) => (
//                   <tr
//                     key={row.masterShipmentHeaderID}
//                     onClick={() => onRowClick(row)}
//                     className={`cursor-pointer hover:bg-gray-100 text-center ${
//                       index % 2 === 0 ? "bg-gray-50" : "bg-white"
//                     }`}
//                   >
//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {index + 1}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {row.masterShipmentHeaderCode}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {row.buCodeSource}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {row.buCodeDestination}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {moment(row.creationDate).format("DD/MM/YYYY hh:mm:ss A")}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {row.manifestClosedDate
//                         ? moment(row.manifestClosedDate).format(
//                             "DD/MM/YYYY hh:mm:ss A"
//                           )
//                         : ""}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {row.transportModelName + " - " + row.transportPlate}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {row.driver1IdentificationNumber +
//                         " - " +
//                         row.driver1Name}
//                     </td>

//                     <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
//                       {row.statusName?.toUpperCase()}
//                     </td>

//                     {/* <td className="px-4 py-2 text-xs text-gray-500 font-semibold">
//                   <div className="flex justify-center items-center">
//                     <button
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         onRowClick(row);
//                       }}
//                       className="bg-gray-300 hover:bg-gray-400 border text-gray-700 rounded-full p-1"
//                     >
//                       <Bars3Icon className="h-5 w-5" aria-hidden="true" />
//                     </button>
//                   </div>
//                 </td> */}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default ManifestHeaderTable;

import { useState } from "react";
import { ManifestHeaderInterface } from "../../interfaces/ManifestInterface";
import { FormTextInput } from "../FormFields";
import moment from "moment";
import PaginationFooter from "../PaginationFooter";
// import PaginationFooter from "./PaginationFooter"; // Asegúrate de importar tu componente de paginación

interface ManifestHeaderTableProps {
  data: ManifestHeaderInterface[] | undefined;
  onRowClick: (manifest: ManifestHeaderInterface) => void;
  Isfilter?: boolean | true;
}

const ManifestHeaderTable: React.FC<ManifestHeaderTableProps> = ({
  data,
  onRowClick,
  Isfilter,
}) => {
  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10; //registros por pagina

  const filteredData = data?.filter(
    (row) =>
      row?.masterShipmentHeaderCode?.includes(filterText) ||
      row?.transportModelName?.includes(filterText) ||
      row?.transportPlate?.includes(filterText) ||
      row?.drivers?.toUpperCase().includes(filterText.toUpperCase())
  );

  const pageCount = Math.ceil((filteredData?.length || 0) / rowsPerPage);
  const offset = currentPage * rowsPerPage;
  const currentPageData = filteredData?.slice(offset, offset + rowsPerPage);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  return (
    <div className="w-full">
      {data?.length === 0 ? (
        <p className="text-gray-400 text-center">
          No hay manifiestos en esta lista
        </p>
      ) : (
        <>
          <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
            {Isfilter && (
              <div className="w-full sm:w-2/5 pl-2">
                <label className="block text-m font-medium leading-6 text-gray-900 mb-2 truncate">
                  Filtrar
                </label>
                <FormTextInput
                  type="text"
                  placeholder="Por número de manifiesto, vehículo o conductor"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
            )}
          </div>

          <div className="overflow-x-auto pl-2">
            <table className="table-auto w-full">
              <thead>
                <tr className="text-center">
                  <th className="px-4 py-2 font-semibold text-xs">ITEM</th>
                  <th className="px-4 py-2 font-semibold text-xs">
                    MANIFIESTO
                  </th>
                  <th className="px-4 py-2 font-semibold text-xs">ORIGEN</th>
                  <th className="px-4 py-2 font-semibold text-xs">DESTINO</th>
                  <th className="px-4 py-2 font-semibold text-xs">
                    FECHA CREACIÓN
                  </th>
                  <th className="px-4 py-2 font-semibold text-xs">
                    FECHA CIERRE
                  </th>
                  <th className="px-4 py-2 font-semibold text-xs">VEHÍCULO</th>
                  <th className="px-4 py-2 font-semibold text-xs">CONDUCTOR</th>
                  <th className="px-4 py-2 font-semibold text-xs">ESTATUS</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData?.map((row, index) => (
                  <tr
                    key={row.masterShipmentHeaderID}
                    onClick={() => onRowClick(row)}
                    className={`cursor-pointer hover:bg-gray-100 text-center ${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    }`}
                  >
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {offset + index + 1}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {row.masterShipmentHeaderCode}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {row.buCodeSource}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {row.buCodeDestination}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {moment(row.creationDate).format("DD/MM/YYYY hh:mm:ss A")}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {row.manifestClosedDate
                        ? moment(row.manifestClosedDate).format(
                            "DD/MM/YYYY hh:mm:ss A"
                          )
                        : ""}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {row.transportModelName?.toUpperCase() +
                        " - " +
                        row.transportPlate?.toUpperCase()}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {row.driver1IdentificationNumber +
                        " - " +
                        row.driver1Name?.toUpperCase()}
                    </td>
                    <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                      {row.statusName?.toUpperCase()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <PaginationFooter
            rowCounts={rowsPerPage}
            itemsOffSet={offset}
            totalPages={pageCount}
            totalItems={filteredData?.length || 0}
            handlePageClick={handlePageClick}
            message={filteredData?.length === 100 ? undefined : undefined}
          />
        </>
      )}
    </div>
  );
};

export default ManifestHeaderTable;
