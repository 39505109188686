import React from "react";
import { FormTextInput } from "./FormFields";

interface NumericInputProps {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxLength?: number;
}

const NumericInput: React.FC<NumericInputProps> = (props) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      // Validar que solo se ingresen números
      props.onChange(e);
    }
  };

  return (
    <FormTextInput
      {...props}
      type="text" // Asegúrate de que el tipo sea texto para permitir la validación personalizada
      onChange={handleInputChange}
    />
  );
};

export default NumericInput;
