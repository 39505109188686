import axios from "axios";
import { FingerprintReaderInterface } from "../interfaces";
import { alertService } from "./alertService";

const baseURLAPI = process.env.REACT_APP_API_PRINTER_HOST;

export const FingerprintEnroll = async () => {
  const uri = baseURLAPI;

  try {
    const response = await axios.post(`${uri}/fingerprint/enroll`);
    if (response && response.status === 200) {
      const data: FingerprintReaderInterface = response.data;
      return data;
    }
  } catch (error) {
    const message = (error as any)?.response?.data?.errorMessage;
    const errorCode = (error as any)?.response?.data?.errorCode;
    if (errorCode === "202") {
      alertService.error(
        "Conecte el dispositivo captahuellas",
        "El captahuellas no fue detectado, por favor verifique que esté conectado correctamente."
      );
    } else {
      alertService.error("No se pudo captar la huella", message);
    }
  }
  return null;
};
