import { useMemo, useState } from "react";
import {
  ManifestDetailInterface,
  ManifestDetailSingleInterface,
  ManifestIncidence,
  ManifestShipmentIncidents,
} from "../../interfaces/ManifestInterface";
import { FormCheckbox, FormTextInput } from "../FormFields";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MixedFilter, MixedFilterDisclosure } from "../FilterDisclosure";
import LoadingIcon from "../LodingIcon";

interface dispatchTableProps {
  data?: ManifestDetailSingleInterface[] | null;
  showDayCreation?: boolean | true;
  showColumStatus?: boolean | true;
  onAdd?: (newRow: ManifestDetailInterface) => void;
  onRowClick?: (shipmentNumber: string) => void;
}

export const manifestShipmentIncident = (status: ManifestShipmentIncidents) => {
  switch (status) {
    case ManifestShipmentIncidents.ANULADAS:
      return "Anuladas";
    case ManifestShipmentIncidents.FALTANTES:
      return "Faltantes";
    default:
      return "Desconocida";
  }
};

// const INITIAL_SHIPMENT_INCIDENTS = [
//   ManifestShipmentIncidents.ANULADAS,
//   ManifestShipmentIncidents.FALTANTES,
// ].map((status) => ({
//   name: manifestShipmentIncident(status),
//   value: status,
//   checked: false,
// }));

const ManifestDetailsTable: React.FC<dispatchTableProps> = ({
  data,
  showDayCreation,
  showColumStatus,
  onAdd,
  onRowClick,
}) => {
  const [tableData, setTableData] = useState(data);
  const [filterText, setFilterText] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  // const [incidents, setIncidents] = useState(INITIAL_SHIPMENT_INCIDENTS);
  // const filters = useMemo(() => {
  //   const result: MixedFilter<any>[] = [];

  //   result.push({
  //     title: "Guías",
  //     isMultiple: true,
  //     multipleOptions: incidents,
  //     onSelectMultipleOption: setIncidents,
  //   });

  //   return result;
  // }, [incidents]);

  const filteredData = data?.filter(
    (row) =>
      row.statusID !== 34 &&
      (row.shipmentNumber.includes(filterText) ||
        row.serviceName?.includes(filterText) ||
        row.locationDestinationCode.includes(filterText)) &&
      (!showErrors || row.incidence === ManifestIncidence.ERROR)
  );

  // const handleDelete = (id: string) => {
  //   const confirmDelete = window.confirm(
  //     "¿Estás seguro de que deseas eliminar este registro?"
  //   );
  //   if (confirmDelete) {
  //     const updatedData = tableData?.filter(
  //       (row) => row.containerDetailID !== id
  //     );
  //     setTableData(updatedData);
  //   }
  // };

  return (
    <div className="w-full">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
        <div className="w-full sm:w-2/3 mb-4">
          <label className="block text-m font-medium leading-6 text-gray-900 mb-2 truncate">
            Filtrar
          </label>
          <FormTextInput
            type="text"
            placeholder="Por número de guía, origen, destino o servicio"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        {/* <div className="flex items-center gap-1 mt-4 sm:mt-6 sm:w-2/6 sm:pl-3 sm:ml-2 sm:justify-end">        
          <div className="flex flex-col items-end justify-end">
            <MixedFilterDisclosure
              showCount
              showClear
              title="Filtros"
              filters={filters}
              className="p-0 sm:pt-10 mb-4"
            />
          </div>
        </div> */}
      </div>

      {/* <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead> */}
      <div className="overflow-x-auto max-h-96 overflow-y-auto">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-center">
              {/* Item,Guía,Origen,Servicio,Pieza,Peso,Destino,Dias Activas,Estatus */}
              <th className="px-4 py-2 font-semibold text-xs">ITEM</th>

              <th className="px-4 py-2 font-semibold text-xs">GUÍA</th>

              <th className="px-4 py-2 font-semibold text-xs">ORIGEN</th>

              <th className="px-4 py-2 font-semibold text-xs">DESTINO</th>

              <th className="px-4 py-2 font-semibold text-xs">SERVICIO</th>

              <th className="px-4 py-2 font-semibold text-xs">PIEZA</th>

              <th className="px-4 py-2 font-semibold text-xs">TOTAL PIEZAS</th>

              <th className="px-4 py-2 font-semibold text-xs">PESO</th>

              {showDayCreation && (
                <th className="px-4 py-2 font-semibold text-xs truncate">
                  DÍAS CREACIÓN
                </th>
              )}
              {showColumStatus && (
                <th className="px-4 py-2 font-semibold text-xs">ESTATUS</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((row, index) => (
              <tr
                className={`cursor-pointer hover:bg-gray-100 text-center transition-opacity duration-500 ${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                }`}
                key={index}
                title={[row.errors, row.incidenceWarning ? row.warnings : ""]
                  .filter((message) => message)
                  .join(" | ")}
                onClick={() => onRowClick && onRowClick(row.shipmentNumber)}
              >
                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {/* {index + 1} */}
                  {row.lineSeqNumber}
                </td>

                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {row.shipmentNumber}
                </td>

                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {row.buCodeSource}
                </td>

                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {row.locationDestinationCode}
                </td>

                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {row.serviceName?.toUpperCase()}
                </td>

                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {row.pieceNumber}
                </td>

                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {row.totalPieces}
                </td>

                <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                  {row.physicalWeight}
                </td>

                {showDayCreation && (
                  <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
                    {row.daysCreation}
                  </td>
                )}

                {showColumStatus && (
                  <td className="px-4 py-2 text-xs text-gray-500 font-semibold">
                    <div className="flex justify-center items-center space-x-1">
                      {row.incidence === ManifestIncidence.NOT_VALIDATED ? (
                        <LoadingIcon size="18px" />
                      ) : row.incidence === ManifestIncidence.NO_ERROR ? (
                        <CheckCircleIcon className="text-green-600 w-7 h-7" />
                      ) : row.incidence === ManifestIncidence.WARNING ? (
                        <ExclamationTriangleIcon className="text-orange-600 w-7 h-7" />
                      ) : (
                        <ExclamationCircleIcon className="text-red-600 w-7 h-7" />
                      )}
                      {row.incidenceWarning && (
                        <ExclamationTriangleIcon className="text-orange-600 w-7 h-7" />
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManifestDetailsTable;
