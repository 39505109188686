import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  InfoDataManifest,
  ManifestDetailInterface,
  ManifestHeaderInterface,
} from "../../interfaces/ManifestInterface";

interface ManifestState {
  manifests: ManifestHeaderInterface[];
  infoDataManifest: InfoDataManifest[];
}

const initialState: ManifestState = {
  manifests: [],
  infoDataManifest: [
    {
      buSource: "",
      routeID: "",
      routeName: "",
      buCodeConsignee: "",
      buConsigneeName: "",
    },
  ],
};

const manifest = createSlice({
  name: "manifest",
  initialState,
  reducers: {
    setManifests: (state, action: PayloadAction<ManifestHeaderInterface[]>) => {
      state.manifests = action.payload;
    },
    addManifest: (state, action: PayloadAction<ManifestHeaderInterface>) => {
      state.manifests.push(action.payload);
    },

    updateManifest: (state, action: PayloadAction<ManifestHeaderInterface>) => {
      const index = state.manifests.findIndex(
        (manifest) =>
          manifest.masterShipmentHeaderID ===
          action.payload.masterShipmentHeaderID
      );
      if (index !== -1) {
        state.manifests[index] = {
          ...state.manifests[index],
          ...action.payload,
        };
      }
    },
    removeManifest: (state, action: PayloadAction<string>) => {
      state.manifests = state.manifests.filter(
        (manifest) => manifest.masterShipmentHeaderID !== action.payload
      );
    },
    addManifestDetail: (
      state,
      action: PayloadAction<{ id: string; detail: ManifestDetailInterface }>
    ) => {
      const { id, detail } = action.payload;
      const manifest = state.manifests.find(
        (m) => m.masterShipmentHeaderID === id
      );
      if (manifest) {
        manifest.manifestDetail = manifest.manifestDetail || [];
        manifest.manifestDetail.push(detail);
      }
    },
    setManifestDetails: (
      state,
      action: PayloadAction<{ id: string; details: ManifestDetailInterface[] }>
    ) => {
      const { id, details } = action.payload;
      const manifest = state.manifests.find(
        (m) => m.masterShipmentHeaderID === id
      );
      if (manifest) {
        manifest.manifestDetail = details;
      }
    },

    //   updateManifestDetail: (
    //     state,
    //     action: PayloadAction<{ id: string; detail: ManifestDetailInterface }>
    //   ) => {
    //     const { id, detail } = action.payload;
    //     const manifest = state.manifests.find((m) => m.masterShipmentHeaderID === id);
    //     if (manifest && manifest.manifestDetails) {
    //       const detailIndex = manifest.manifestDetails.findIndex(
    //         (d) => d.containerDetailID === detail.containerDetailID
    //       );
    //       if (detailIndex !== -1) {
    //         manifest.manifestDetails[detailIndex] = { ...manifest.manifestDetails[detailIndex], ...detail };
    //       }
    //     }
    //   },
    removeManifestDetail: (
      state,
      action: PayloadAction<{ id: string; detailID: string }>
    ) => {
      const { id, detailID } = action.payload;
      const manifest = state.manifests.find(
        (m) => m.masterShipmentHeaderID === id
      );
      if (manifest && manifest.manifestDetail) {
        manifest.manifestDetail = manifest.manifestDetail.filter(
          (detail) => detail.containerDetailID !== detailID
        );
      }
    },

    addDataBasicManifest: (state, action: PayloadAction<InfoDataManifest>) => {
      state.infoDataManifest = state.infoDataManifest || [];
      state.infoDataManifest.push(action.payload);
    },

    updateDataBasicManifest: (
      state,
      action: PayloadAction<InfoDataManifest>
    ) => {
      const index = state.infoDataManifest.findIndex(
        (infoManifest) => infoManifest.buSource === action.payload.buSource
      );
      if (index !== -1) {
        state.infoDataManifest[index] = {
          ...state.infoDataManifest[index],
          ...action.payload,
        };
      }
    },
  },
});

export const {
  setManifests,
  addManifest,
  updateManifest,
  removeManifest,
  addManifestDetail,
  setManifestDetails,
  // updateManifestDetail,
  removeManifestDetail,
  addDataBasicManifest,
  updateDataBasicManifest,
} = manifest.actions;

export default manifest.reducer;
