import {
  useState,
  FC,
  KeyboardEvent,
  forwardRef,
  useImperativeHandle,
} from "react";
import { FormTextInput } from "./FormFields";
import { ProcessBarcode } from "../utils";
import { ShipmentGuideModel } from "../interfaces";
import { alertService } from "../services";
interface InputComponentProps {
  onValueChange: (value: ShipmentGuideModel) => void;
  maxLength?: number;
}
export interface BarcodeScannerComponentHandle {
  clearBarcode: () => void;
}

const BarcodeScannerComponent = forwardRef<
  BarcodeScannerComponentHandle,
  InputComponentProps
>(({ onValueChange, maxLength }, ref) => {
  const [barcode, setBarcode] = useState("");

  useImperativeHandle(ref, () => ({
    clearBarcode: () => setBarcode(""),
  }));

  const handleChange = (e: any) => {
    setBarcode(e.target.value);
  };

  const handleKeyUp = async (e: KeyboardEvent) => {
    if (e.key === "Enter" && barcode !== "") {
      try {
        let BarCodeSplit: any = await ProcessBarcode(barcode);
        onValueChange(BarCodeSplit);
        setBarcode("");
        e.stopPropagation();
      } catch (error) {
        alertService.error(`${error}`);
      }
    }
  };

  return (
    //se cambio el evento onKeyDown, por el onKeyUp
    <FormTextInput
      type="text"
      placeholder="Escanee el código de barra"
      value={barcode}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      maxLength={maxLength}
    />
  );
});

export default BarcodeScannerComponent;
