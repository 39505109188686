import { FC, useState } from "react";
import { FingerprintEnroll } from "../services";
import { PrimaryButton, SecondaryButton } from "./Buttons";

interface FingerprintReaderProps {
  fingerprint?: string;
  setFingerprint: (fingerprint?: string) => void;
  setTemplate: (template?: string) => void;
}

const FingerprintReader: FC<FingerprintReaderProps> = ({
  fingerprint,
  setFingerprint,
  setTemplate,
}) => {
  const [awaitingFingerprint, setAwaitingFingerprint] =
    useState<boolean>(false);

  const handleButtonClick = async () => {
    setAwaitingFingerprint(true);
    const result = await FingerprintEnroll();
    setFingerprint(result?.imageBase64);
    setTemplate(result?.template);
    setAwaitingFingerprint(false);
  };

  return (
    <div className="flex flex-col items-center ">
      {fingerprint && (
        <>
          <img
            src={`data:image/png;base64,${fingerprint}`}
            alt="FingerPrint"
            style={{ width: "200px" }}
          />
          <SecondaryButton
            className="mt-2"
            onClick={handleButtonClick}
            disabled={awaitingFingerprint}
          >
            {awaitingFingerprint
              ? "Coloque el pulgar..."
              : "Volver a tomar huella"}
          </SecondaryButton>
        </>
      )}
      {!fingerprint && (
        <>
          <div
            style={{
              width: "200px",
              height: "200px",
              border: "1px solid black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Huella dactilar
          </div>
          <PrimaryButton
            className="mt-2"
            onClick={handleButtonClick}
            disabled={awaitingFingerprint}
          >
            {awaitingFingerprint ? "Coloque el pulgar..." : "Tomar huella"}
          </PrimaryButton>
        </>
      )}
    </div>
  );
};
export default FingerprintReader;
