import { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import { useAppSelector } from "../../store/hooks";
import {
  AccountInterface,
  AccountRequestInterface,
  DocumentInterface,
} from "../../interfaces";
import { FormSelect, FormTextInput } from "../FormFields";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import CreateClientModal from "../Account/CreateClientModal";
import UpdateClientModal from "../Account/UpdateClientModal";
import {
  loaderService,
  createReplacement,
  getFilteredAccounts,
  updateAccount,
  getAccountByIdentification,
  alertService,
} from "../../services";
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

enum SustitutionPhase {
  CONFIRM,
  ID_DOCUMENT,
  ACCOUNT_NOT_FOUND,
  ACCOUNT,
  ERROR,
  SUCCESS,
}

interface FiscalSustitutionModalProps {
  document: DocumentInterface;
  accountBillTo: AccountInterface;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FiscalSustitutionModal: FC<FiscalSustitutionModalProps> = ({
  document,
  accountBillTo,
  open,
  setOpen,
}) => {
  const navigate = useNavigate();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user.user);
  const userBU = useAppSelector((state) => state.user.businessUnit);
  const idOptions = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [address, setAddress] = useState("");
  const [idDocument, setIdDocument] = useState("");
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [phase, setPhase] = useState(SustitutionPhase.CONFIRM);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openCreationModal, setOpenCreationModal] = useState(false);
  const [newAccountBillTo, setNewAccountBillTo] = useState<AccountInterface>();
  const [idType, setIdType] = useState(
    idOptions[0].abreviationName.slice(0, 1)
  );

  const showBackButton = useMemo(() => {
    return (
      phase === SustitutionPhase.ID_DOCUMENT ||
      phase === SustitutionPhase.ACCOUNT_NOT_FOUND ||
      phase === SustitutionPhase.ACCOUNT ||
      phase === SustitutionPhase.ERROR
    );
  }, [phase]);

  const handleAccountSearch = async () => {
    if (!idDocument) return;

    loaderService.start();
    const auxResponse = await getAccountByIdentification(
      idType + "-",
      idDocument
    );
    const responseList = await getFilteredAccounts(
      `${auxResponse.model?.abreviationName}${auxResponse.model?.identificationNumber}`
    );
    const response = responseList.model?.filter(
      (account) =>
        account.abreviationName === idType + "-" &&
        account.identificationNumber === idDocument
    );
    loaderService.stop();

    if (!response || response.length === 0) {
      setPhase(SustitutionPhase.ACCOUNT_NOT_FOUND);
      return;
    }

    if (response.length > 1) {
      alertService.warn(
        "Se encontraron múltiples cuentas con la misma cédula/RIF"
      );
    }

    const account = response[0];
    setName(account.accountFullName);
    setAddress(account.fiscalAddress ?? "");
    setEmail(account.listAccountEmail[0]?.email ?? "");
    setIsEnterprise(
      !!account.agreementID || account.listAuthorizingAccount?.length > 0
    );
    setNewAccountBillTo(account);
    setPhase(SustitutionPhase.ACCOUNT);
  };

  const saveClient = async () => {
    const account: AccountRequestInterface = {
      id: newAccountBillTo!.id,
      accountTypeID: newAccountBillTo!.accountTypeID,
      fiscalPhoneNumber: newAccountBillTo!.fiscalPhoneNumber,
      countryID: newAccountBillTo!.countryID,
      accountCode: newAccountBillTo!.accountCode,
      taxIdentificationTypeID: newAccountBillTo!.taxIdentificationTypeID,
      taxIdentificationTypeCode: idType,
      abreviationName: newAccountBillTo!.abreviationName,
      identificationNumber: idDocument,
      accountFullName: name,
      fiscalAddress: address,
      listAccountEmail: [
        {
          email: email,
          emailTypeID: 1,
          emailID: newAccountBillTo!.listAccountEmail[0].emailID,
          accountID: newAccountBillTo!.id,
        },
      ],
      listAccountPhone: [],
      listAuthorizingAccount: [],
    };

    loaderService.start();
    const response = await updateAccount(account);
    loaderService.stop();

    return !response.didError && !!response.model;
  };

  const handleSustitution = async () => {
    if (
      accountBillTo.id === newAccountBillTo?.id &&
      document.accountOwner?.accountFullName === name &&
      document.accountOwner.fiscalAddress === address &&
      document.accountOwner.abreviationName === idType &&
      document.accountOwner.identificationNumber === idDocument
    ) {
      setError("Los datos fiscales no han cambiado");
      return;
    }

    const success = await saveClient();
    if (!success) {
      setPhase(SustitutionPhase.ERROR);
      setError("Error al guardar el cliente");
      return;
    }

    loaderService.start();
    const response = await createReplacement(
      document.documentID,
      document.documentTypeCode!,
      document.documentNumber!,
      document.fiscalControlNumber!,
      document.documentType!,
      document.status,
      userBU!.code,
      accountBillTo.id,
      accountBillTo.accountFullName,
      accountBillTo.listAccountPhone[0]?.phoneNumber,
      accountBillTo.listAccountEmail[0]?.email,
      accountBillTo.identificationNumber,
      accountBillTo.fiscalAddress ?? "",
      newAccountBillTo!.id,
      name,
      newAccountBillTo!.listAccountPhone[0]?.phoneNumber,
      email,
      idDocument,
      address,
      user!
    );
    loaderService.stop();

    if (!response || response.didError || !response.model) {
      setPhase(SustitutionPhase.ERROR);
      setError(response?.errorMessage ?? "Error al realizar la sustitución");
      return;
    }

    setOpen(false);
    navigate(`/documents/${response.model.documentID}`);
  };

  const back = () => {
    if (phase === SustitutionPhase.ID_DOCUMENT) {
      setPhase(SustitutionPhase.CONFIRM);
    } else if (
      phase === SustitutionPhase.ACCOUNT_NOT_FOUND ||
      phase === SustitutionPhase.ACCOUNT ||
      phase === SustitutionPhase.ERROR
    ) {
      setPhase(SustitutionPhase.ID_DOCUMENT);
    }
  };

  useEffect(() => {
    if (document.accountOwner) {
      const idType = document.accountOwner.abreviationName ?? "";
      const option = idOptions.find((o) => o.abreviationName === idType);
      setIdType((option ?? idOptions[0]).abreviationName.slice(0, 1));
      setIdDocument(document.accountOwner?.identificationNumber);
    }
  }, [document.accountOwner, open, idOptions]);

  useEffect(() => {
    setError("");
  }, [phase]);

  return (
    <Modal
      openModal={open}
      setOpenModal={(value) => {
        setOpen(value);
        setPhase(SustitutionPhase.CONFIRM);
      }}
      className="w-full max-w-[30rem]"
    >
      <div className="flex items-center gap-4">
        <div
          onClick={back}
          className={classNames(
            !showBackButton && "hidden",
            "flex rounded-full p-2 bg-gray-100 cursor-pointer hover:bg-gray-200"
          )}
        >
          <ChevronLeftIcon className="w-6 h-6 text-gray-500" />
        </div>
        <h2 className="text-xl font-semibold">Sustitución de Factura</h2>
      </div>

      {phase === SustitutionPhase.CONFIRM && (
        <>
          <p className="text-lg text-center mt-4">
            ¿Necesitas sustituir esta factura por error en los datos fiscales?
            (Cédula/RIF, Razón social y/o Dirección fiscal)
          </p>

          <div className="flex mt-8 gap-8 w-full justify-between items-center">
            <SecondaryButton className="w-40" onClick={() => setOpen(false)}>
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              className="w-40"
              onClick={() => setPhase(SustitutionPhase.ID_DOCUMENT)}
            >
              Continuar
            </PrimaryButton>
          </div>
        </>
      )}

      {phase === SustitutionPhase.ID_DOCUMENT && (
        <>
          <p className="text-lg mt-4">
            ¿A cuál cédula/RIF deseas emitir la factura?
          </p>

          <div className="flex flex-row w-full mt-4">
            <div className="flex flex-col">
              <FormSelect
                containerClassName="rounded rounded-r-none"
                name="identificationType"
                type="text"
                label="Tipo"
                options={idOptions.map((option) =>
                  option.abreviationName.slice(0, 1)
                )}
                optionString={(option) => option}
                selected={idType}
                onSelectOption={setIdType}
              />
            </div>
            <div className="flex flex-col w-full">
              <FormTextInput
                className="rounded rounded-l-none"
                name="clientIdentifier"
                type="text"
                label="Cédula del cliente"
                placeholder="12345678"
                value={idDocument}
                onChange={(e) => setIdDocument(e.target.value)}
              />
            </div>
          </div>

          <div className="flex mt-8 gap-8 w-full justify-between items-center">
            <SecondaryButton
              className="w-40"
              onClick={() => {
                setPhase(SustitutionPhase.CONFIRM);
                setOpen(false);
              }}
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton className="w-40" onClick={handleAccountSearch}>
              Continuar
            </PrimaryButton>
          </div>
        </>
      )}

      {phase === SustitutionPhase.ACCOUNT_NOT_FOUND && (
        <>
          <p className="mt-4">
            No se encontró una cuenta asociada a la cédula/RIF ingresada.
            ¿Quieres crear un cliente nuevo o editar el cliente al que se le
            facturó originalmente?
          </p>

          <div className="flex mt-8 gap-8 w-full justify-between items-center">
            <SecondaryButton
              className="w-40"
              onClick={() => setOpenCreationModal(true)}
            >
              Cliente Nuevo
            </SecondaryButton>

            <PrimaryButton
              className="w-40"
              onClick={async () => {
                setOpenUpdateModal(true);
              }}
            >
              Editar Cliente
            </PrimaryButton>
          </div>
        </>
      )}

      {phase === SustitutionPhase.ACCOUNT && (
        <>
          <div className="flex flex-col w-full mt-4">
            <FormTextInput
              label="Nombre o Razón Social"
              value={name}
              disabled={isEnterprise}
              onChange={(e) => setName(e.target.value)}
              className="w-full"
            />
            <p
              className={classNames(
                "text-xs text-gray-700",
                !isEnterprise && "hidden"
              )}
            >
              <span className="text-red-600 font-bold">*</span>Este cliente es
              corporativo, así que no se puede modificar su información fiscal.
            </p>
          </div>

          <div className="flex flex-row w-full mt-4">
            <div className="flex flex-col">
              <FormSelect
                disabled
                containerClassName="rounded rounded-r-none"
                name="identificationType"
                type="text"
                label="Tipo"
                options={idOptions.map((option) =>
                  option.abreviationName.slice(0, 1)
                )}
                optionString={(option) => option}
                selected={idType}
                onSelectOption={setIdType}
              />
            </div>
            <div className="flex flex-col w-full">
              <FormTextInput
                disabled
                className="rounded rounded-l-none"
                name="clientIdentifier"
                type="text"
                label="Cédula del cliente"
                placeholder="12345678"
                value={idDocument}
                onChange={(e) => setIdDocument(e.target.value)}
              />
            </div>
          </div>

          <div className="w-full mt-4">
            <FormTextInput
              label="Correo electrónico"
              value={email}
              disabled={isEnterprise}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
            />
          </div>

          <div className="w-full mt-4">
            <FormTextInput
              label="Dirección Fiscal"
              value={address}
              disabled={isEnterprise}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full"
            />
          </div>

          {error && <p className="text-red-600 mt-4">{error}</p>}

          <div className="flex mt-8 gap-8 w-full justify-end items-center">
            <PrimaryButton
              disabled={loading}
              className="w-40"
              onClick={handleSustitution}
            >
              Sustituir
            </PrimaryButton>
          </div>
        </>
      )}

      {phase === SustitutionPhase.ERROR && (
        <div className="flex flex-1 items-center flex-col gap-4">
          <XCircleIcon className="text-red-600 w-32 h-32" />

          <p className="mt-2 text-lg font-medium text-gray-600 text-center">
            Hubo un error al realizar la sustitución.
          </p>
          <p className="-mt-4 text-gray-800 text-center">{error}</p>

          <div className="flex mt-2 gap-8 w-full justify-end items-center">
            <PrimaryButton onClick={() => setOpen(false)} className="w-40">
              Cerrar
            </PrimaryButton>
          </div>
        </div>
      )}

      {phase === SustitutionPhase.SUCCESS && (
        <div className="flex flex-1 items-center flex-col gap-4">
          <CheckCircleIcon className="text-green-600 w-32 h-32" />

          <p className="mt-2 text-lg font-medium text-gray-600 text-center">
            Sustitución realizada exitosamente.
          </p>

          <div className="w-full">
            <SecondaryButton onClick={() => setOpen(false)} className="w-full">
              Cerrar
            </SecondaryButton>
          </div>
        </div>
      )}

      <CreateClientModal
        disabledIdDocument
        idNumber={idDocument}
        openModal={openCreationModal}
        setOpenModal={setOpenCreationModal}
        setSelectedItem={(client) => {
          const idType = client.abreviationName ?? "";
          const option = idOptions.find((o) => o.abreviationName === idType);
          setIdType((option ?? idOptions[0]).abreviationName.slice(0, 1));
          setIdDocument(client.identificationNumber);
          setName(client.accountFullName);
          setAddress(client.fiscalAddress ?? "");
          setEmail(client.listAccountEmail[0]?.email ?? "");
          setNewAccountBillTo(client);
          setPhase(SustitutionPhase.ACCOUNT);
        }}
      />

      {!!accountBillTo && (
        <UpdateClientModal
          disabledIdDocument
          openModal={openUpdateModal}
          setOpenModal={setOpenUpdateModal}
          setSelectedItem={(client) => {
            const idType = client.abreviationName ?? "";
            const option = idOptions.find((o) => o.abreviationName === idType);
            setIdType((option ?? idOptions[0]).abreviationName.slice(0, 1));
            setIdDocument(client.identificationNumber);
            setName(client.accountFullName);
            setAddress(client.fiscalAddress ?? "");
            setEmail(client.listAccountEmail[0]?.email ?? "");
            setNewAccountBillTo(client);
            setPhase(SustitutionPhase.ACCOUNT);
          }}
          selectedItem={{
            ...accountBillTo,
            abreviationName: idType + "-",
            identificationNumber: idDocument,
          }}
        />
      )}
    </Modal>
  );
};
