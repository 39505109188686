import { useEffect, useState } from "react";
import { PrimaryButton } from "../components/Buttons";
import {
  ManifestHeaderInterface,
  ManifestPhase,
  ManifestDetailInterface,
  ManifestStatus,
  ManifestType,
} from "../interfaces/ManifestInterface";

import LoadingIcon from "../components/LodingIcon";
import ManifestHeaderTable from "../components/Manifest/ManifestHeaderTable";
import moment from "moment";
import {
  getManifestDetail,
  getManifestPosponed,
  updateManifestStatus,
} from "../services/manifestServices";
import ManifestDetailModal from "../components/Manifest/ManifestDetailModal";
import NewDispatch from "./Manifest/NewDispatch";
import classNames from "classnames";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import SearchManifest from "../components/Manifest/SearchManifest";
import { useAppSelector } from "../store/hooks";
import ReusableModal from "../components/Manifest/ReusableModal";
import { useDispatch } from "react-redux";
import { removeManifest } from "../store/slices/manifest";

interface DispatchFormState {
  masterShipmentHeaderCode: string;
  statusID?: number | null;
}

const Dispatch = () => {
  const [postponedData, setPostponedData] = useState<
    ManifestHeaderInterface[] | null
  >([]);
  const [generatedData, setGeneratedData] = useState<
    ManifestHeaderInterface[] | null
  >([]);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isLoadingGene, setIsLoadingGene] = useState(false);
  const [selectedManifest, setSelectedManifest] =
    useState<ManifestHeaderInterface | null>(null);

  // const [selectedManifestDraft, setSelectedManifestDraft] =
  //   useState<ManifestHeaderInterface | null>(null);
  // const [isNewManifestDraft, setIsNewManifestDraft] = useState(false);
  // const [detailDataDraft, setDetailDataDraft] = useState<
  //   ManifestDetailInterface[] | null
  // >([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detailData, setDetailData] = useState<
    ManifestDetailInterface[] | null
  >([]);
  // const [isNewManifest, setIsNewManifest] = useState(false);
  const [phase, setPhase] = useState<ManifestPhase>(
    ManifestPhase.DISPATCH_HOME
  );
  const [openDispatchHomeModal, setOpenDispatchHomeModal] = useState(false);
  const [openNewDispatchModal, setOpenNewDispatchModal] = useState(false);

  const [manifestFormState, setManifestFormState] = useState<DispatchFormState>(
    {
      masterShipmentHeaderCode: "",
      statusID: null,
    }
  );
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const dispatch = useDispatch();
  const manifests = useAppSelector((state) => state.manifest.manifests);

  // const selectTab = (tab: number) => {
  //   setTab(tab);
  // };

  // <div className="ml-8 my-6">
  //           <BackButton handleOnRegret={handleOnRegret} />
  //         </div>

  const selectPhase = (phase: ManifestPhase) => {
    setPhase(phase);
  };

  // Redux state
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    // Realiza la búsqueda inicial al montar el componente
    const fetchInitialData = async () => {
      const buCode = user.businessUnit?.code;
      if (!buCode) return;

      setIsLoadingPost(true);

      // se busca en redux si se tiene un manifiesto de despacho guardado
      let manifestHeaderLocal = manifests.find(
        (x) => x.manifestTypeID === ManifestType.DESPACHO
      );

      if (manifestHeaderLocal) {
        handleSearch([manifestHeaderLocal], "postponed");
      } else {
        const fechaActual = moment();
        const initialData = await getManifestPosponed(
          ManifestType.DESPACHO,
          fechaActual.subtract(3, "days").toDate(),
          moment().toDate(),
          buCode
        );

        handleSearch(
          !initialData.didError ? initialData?.model : null,
          "postponed"
        );
      }

      setIsLoadingPost(false);
    };

    fetchInitialData();
  }, [manifests, user.businessUnit?.code]);

  useEffect(() => {
    if (!!manifestFormState.masterShipmentHeaderCode && shouldFetchData) {
      selectPhase(ManifestPhase.DISPATCH_NEW);
    }
  }, [shouldFetchData, manifestFormState.masterShipmentHeaderCode]);

  useEffect(() => {
    if (detailData && selectedManifest) {
      setIsModalOpen(true);
    }
  }, [detailData, selectedManifest]);

  // se asigna el resultado obtenido del API
  const handleSearch = (
    searchData: ManifestHeaderInterface[] | null,
    type: "postponed" | "generated"
  ) => {
    if (searchData && searchData.length > 0) {
      searchData.sort((a, b) => {
        const codeA = a.masterShipmentHeaderCode || "";
        const codeB = b.masterShipmentHeaderCode || "";
        return codeB.localeCompare(codeA);
      });
      if (type === "postponed") {
        setPostponedData(searchData);
      } else {
        setGeneratedData(searchData);
      }
    }
  };

  // click al table manifestHeader, y su status es Generado = 35
  const handleRowClick = async (manifest: ManifestHeaderInterface) => {
    setSelectedManifest(manifest);
    let detailDa = null;

    if (manifest?.masterShipmentHeaderCode) {
      setIsLoadingGene(true);
      detailDa = await getManifestDetail(manifest.masterShipmentHeaderCode);
    }

    if (detailDa && detailDa[0].manifestDetail) {
      setDetailData(detailDa[0].manifestDetail);
    }

    setIsLoadingGene(false);
  };

  // const handleRowDraftClick = async (manifest: ManifestHeaderInterface) => {
  //   setSelectedManifestDraft(manifest);
  //   setIsNewManifestDraft(true);

  //   let detailDataDraf = null;

  //   if (manifest.masterShipmentHeaderCode) {
  //     detailDataDraf = await getManifestDetail(
  //       manifest.masterShipmentHeaderCode
  //     );

  //     if (detailDataDraf && detailDataDraf[0].manifestDetail) {
  //       setDetailDataDraft(
  //         detailDataDraf ? detailDataDraf[0].manifestDetail : null
  //       );
  //     }
  //   }
  // };

  // const handleOnRegret = () => {
  //   const { masterShipmentHeaderCode, statusID } = manifestFormState;
  //   if (masterShipmentHeaderCode) {
  //     if (statusID) {
  //       setOpenDispatchHomeModal(true);
  //     } else {
  //       setPhase(ManifestPhase.DISPATCH_HOME);
  //     }
  //   } else {
  //     setPhase(ManifestPhase.DISPATCH_HOME);
  //   }
  // };

  // recuperar el manifiesto postergado al darle clic en la tabla manifestHeader
  const handleShouldDraft = () => {
    let rowDraft = postponedData?.find(
      (x) => x.statusID === ManifestStatus.POSTERGADO
    );

    if (rowDraft?.masterShipmentHeaderCode) {
      setManifestFormState({
        ...manifestFormState,
        masterShipmentHeaderCode: rowDraft?.masterShipmentHeaderCode,
        statusID: rowDraft?.statusID,
      });
      setShouldFetchData(true);
    } else {
      setShouldFetchData(false);
    }
  };

  const handleCancel = () => {
    setOpenDispatchHomeModal(false);
  };

  const handleAcceptNew = async () => {
    setOpenDispatchHomeModal(false);
    selectPhase(ManifestPhase.DISPATCH_NEW);

    // se debe mantener solo (1) un objeto de manifesto de despacho en el local storage
    // en caso de ya exitir uno, se manda a eliminar
    let removeLocalManifest = manifests.find(
      (x) => x.manifestTypeID === ManifestType.DESPACHO
    );

    if (removeLocalManifest) {
      dispatch(
        removeManifest(removeLocalManifest?.masterShipmentHeaderID as string)
      );
    }

    let manifestUpdateStatus = postponedData?.find(
      (x) => x.statusID === ManifestStatus.POSTERGADO
    )?.masterShipmentHeaderID;

    // actualizar el estatus del manifiesto que se esta descartando. 'Inactivarlo'
    if (manifestUpdateStatus) {
      await updateManifestStatus(
        manifestUpdateStatus,
        ManifestStatus.INACTIVO,
        user.user?.userLogin as string
      );
    }
  };

  const handleAcceptHome = () => {
    setOpenDispatchHomeModal(false);
    setPhase(ManifestPhase.DISPATCH_HOME);
  };

  const message =
    manifestFormState.statusID === 31
      ? "¿Está seguro de salir de la pantalla, sin guardar en borrador ó cerrar el manifiesto? ¡Se perderá la información!"
      : "¿Está seguro de salir de la pantalla? El manifiesto ya está en estatus postergado";

  const messageNewDispatch =
    "¿Esta seguro de crear un nuevo despacho? ¡Tiene uno guardado en borrador, si continua se perderá la información!";

  const handleNewDispatch = () => {
    if (
      postponedData &&
      postponedData?.some((x) => x.statusID === ManifestStatus.POSTERGADO)
    ) {
      setOpenNewDispatchModal(true);
    } else {
      setManifestFormState({ masterShipmentHeaderCode: "", statusID: null });
      setShouldFetchData(false);
      selectPhase(ManifestPhase.DISPATCH_NEW);
    }
  };

  return (
    <main className="lg:pl-72">
      {/* Header */}
      {phase === ManifestPhase.DISPATCH_HOME && (
        <>
          <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-4">
            <header className="ml-4 text-2xl font-bold text-gray-700 ">
              Manifiesto de despacho
            </header>
            <PrimaryButton className="pr-4" onClick={handleNewDispatch}>
              <DocumentTextIcon
                className="h-5 w-5 flex-none text-gray-200 mr-1"
                aria-hidden="true"
              />
              Nuevo
            </PrimaryButton>
          </div>
          <div className="flex flex-1 flex-col gap-6 px-8 mb-2">
            {/* Header*/}
            <div
              className={classNames(
                "flex flex-1 flex-col bg-white gap-4 rounded-lg border px-6 py-6 w-full"
              )}
            >
              <h1 className="text-2xl font-semibold text-gray-900 truncate mb-2">
                Manifiestos en Borrador
              </h1>
              {/* <SearchManifest manifestType={1} onSearch={handleSearch} /> */}
              <div
                className={classNames(
                  "flex flex-1 flex-row bg-white gap-4 rounded-lg"
                )}
              >
                {isLoadingPost ? (
                  <LoadingIcon />
                ) : (
                  <ManifestHeaderTable
                    data={
                      postponedData?.find(
                        (x) => x.statusID === ManifestStatus.POSTERGADO
                      )
                        ? [
                            postponedData.find(
                              (x) => x.statusID === ManifestStatus.POSTERGADO
                            )!,
                          ]
                        : []
                    }
                    onRowClick={handleShouldDraft}
                    Isfilter={false}
                  />
                )}
              </div>
            </div>
            <div
              className={classNames(
                "flex flex-1 flex-col bg-white gap-4 rounded-lg border px-6 py-6 w-full"
              )}
            >
              <h1 className="text-2xl font-semibold text-gray-900 truncate mb-4">
                Manifiestos Generados
              </h1>
              <SearchManifest
                manifestType={1}
                onSearch={(data) => handleSearch(data, "generated")}
                setIsLoadingGene={setIsLoadingGene}
              />
              <div
                className={classNames(
                  "flex flex-1 flex-row bg-white gap-4 rounded-lg"
                )}
              >
                {isLoadingGene ? (
                  <LoadingIcon />
                ) : (
                  <ManifestHeaderTable
                    data={generatedData?.filter(
                      (x) => x.statusID === ManifestStatus.GENERADO
                    )}
                    onRowClick={handleRowClick}
                    Isfilter={true}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {phase === ManifestPhase.DISPATCH_NEW && (
        <>
          <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-4">
            <header className="ml-4 text-2xl font-bold text-gray-700 ">
              Crear Despacho
            </header>
          </div>

          <NewDispatch
            setManifestFormState={setManifestFormState}
            shouldFetchData={shouldFetchData}
            manifestFormState={manifestFormState}
          />
        </>
      )}
      {isModalOpen && selectedManifest && (
        <ManifestDetailModal
          openModal={isModalOpen}
          setOpenModal={setIsModalOpen}
          manifest={selectedManifest}
          detailData={detailData}
        />
      )}
      {/* Modal de consulta si esta en dispatch y quiere regresar a la pantalla principal de despacho */}
      <ReusableModal
        openModal={openDispatchHomeModal}
        setOpenModal={setOpenDispatchHomeModal}
        title="Creación de despacho"
        message={message}
        iconType="question"
        onCancel={handleCancel}
        onAccept={handleAcceptHome}
        showCancelButton={true}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />
      {/* Modal de consulta si esta en la pantalla principal de despacho y quiere crear uno nuevo */}
      <ReusableModal
        openModal={openNewDispatchModal}
        setOpenModal={setOpenNewDispatchModal}
        title="Creación de despacho"
        message={messageNewDispatch}
        iconType="question"
        onCancel={handleCancel}
        onAccept={handleAcceptNew}
        showCancelButton={true}
        cancelButtonText="Cancelar"
        acceptButtonText="Aceptar"
      />
    </main>
  );
};

export default Dispatch;
