import api from "./api";
import { ResponseInterface } from "../interfaces";

const baseURLAPI = process.env.REACT_APP_API_HOST;

// Add AM to service
export const saveLiabilityWaver = async (
  fingerprint: File | undefined,
  photoAttachment: File | undefined,
  shipmentHeaderIDList: string[],
  isPhysicalLiabilityWaiver: boolean,
  creationUser: string,
  statusID: number
): Promise<ResponseInterface<boolean>> => {
  const uri = `${baseURLAPI}/LiabilityWaiver`;
  const formData = new FormData();
  if (fingerprint) formData.append("Fingerprint", fingerprint);
  if (photoAttachment) formData.append("PhotoAttachment", photoAttachment);
  formData.append("ShipmentHeaderIDList", shipmentHeaderIDList.join(","));
  formData.append(
    "IsPhysicalLiabilityWaiver",
    isPhysicalLiabilityWaiver.toString()
  );
  formData.append("CreationUser", creationUser);
  formData.append("StatusID", statusID.toString());

  try {
    const response = await api.post(uri, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
