import React, { useState, useEffect } from "react";
import { FormSearch } from "../FormFields";

export interface Driver {
  accountID: string;
  identificationNumber: string;
  accountFullName: string;
}

function DriverSearch({
  id,
  name,
  drivers,
  onDriverSelect,
  initialValue,
}: {
  id: string | "driver";
  name: string | "driver";
  drivers: Driver[];
  onDriverSelect: (driver: Driver) => void;
  initialValue?: string;
}) {
  const [searchTerm, setSearchTerm] = useState(""); // Inicializado searchTerm
  const [filteredDrivers, setFilteredDrivers] = useState<Driver[]>(drivers);

  useEffect(() => {
    if (initialValue) {
      const selectedDriver = drivers.find(
        (driver) => driver.accountID === initialValue
      );
      if (selectedDriver) {
        setSearchTerm(
          `${selectedDriver.identificationNumber} - ${selectedDriver.accountFullName}`
        );
        onDriverSelect(selectedDriver);
      }
    } else {
      setSearchTerm(""); // Limpiar el campo de búsqueda si initialValue es una cadena vacía
    }
  }, [initialValue, drivers, onDriverSelect]);

  useEffect(() => {
    const filtered = drivers.filter(
      (driver) =>
        driver.identificationNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        driver.accountFullName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDrivers(filtered);
  }, [searchTerm, drivers]);

  const handleOptionSelect = (selectedOption: string) => {
    const selectedDriver = filteredDrivers.find(
      (driver) => driver.accountID === selectedOption
    );

    if (selectedDriver) {
      setSearchTerm(
        `${selectedDriver.identificationNumber} - ${selectedDriver.accountFullName}`
      );
      onDriverSelect(selectedDriver);
    } else {
      console.error("Conductor no encontrado:", selectedOption);
    }
  };

  return (
    <div className="relative w-full">
      <FormSearch
        id={id}
        name={name}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        options={filteredDrivers.map((driver) => ({
          label: `${driver.identificationNumber} - ${driver.accountFullName}`,
          value: driver.accountID,
        }))}
        onSelectOption={(option) => handleOptionSelect(option.value)}
        RenderOption={({ option }) => <p> {option.label} </p>}
        placeholder="Buscar conductor"
      />
    </div>
  );
}

export default DriverSearch;
