import React, { useState, useEffect } from "react";
import { FormSearch } from "../FormFields";
import { Vehicles } from "../../interfaces/ManifestInterface";

function VehicleSearch({
  id,
  name,
  vehicles,
  onVehicleSelect,
  initialValue,
}: {
  id?: string | "vehicle";
  name: string | "vehicle";
  vehicles: Vehicles[];
  onVehicleSelect: (vehicle: Vehicles) => void;
  initialValue?: string;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredVehicles, setFilteredVehicles] =
    useState<Vehicles[]>(vehicles);

  useEffect(() => {
    if (initialValue) {
      const selectedVehicle = vehicles.find(
        (vehicle) => vehicle.transportID === initialValue
      );
      if (selectedVehicle) {
        setSearchTerm(
          `${selectedVehicle.transportPlate} - ${selectedVehicle.transportModel}`
        );
        onVehicleSelect(selectedVehicle);
      }
    } else {
      setSearchTerm(""); // Limpiar el campo de búsqueda si initialValue es una cadena vacía
    }
  }, [initialValue, vehicles, onVehicleSelect]);

  useEffect(() => {
    const filtered = vehicles.filter(
      (vehicle) =>
        vehicle.transportModel
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        vehicle.transportPlate.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredVehicles(filtered);
  }, [searchTerm, vehicles]);

  const handleOptionSelect = (selectedOption: string) => {
    const selectedVehicle = filteredVehicles.find(
      (vehicle) => vehicle.transportID === selectedOption
    );

    if (selectedVehicle) {
      setSearchTerm(
        `${selectedVehicle.transportPlate} - ${selectedVehicle.transportModel}`
      );
      onVehicleSelect(selectedVehicle);
    } else {
      console.error("Vehículo no encontrado:", selectedOption);
    }
  };

  return (
    <div className="relative w-full">
      <FormSearch
        id={id}
        name={name}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        options={filteredVehicles.map((vehicle) => ({
          label: `${vehicle.transportPlate} - ${vehicle.transportModel}`,
          value: vehicle.transportID,
        }))}
        onSelectOption={(option) => handleOptionSelect(option.value ?? "")}
        RenderOption={({ option }) => <p> {option.label} </p>}
        placeholder="Buscar vehículo"
      />
    </div>
  );
}

export default VehicleSearch;
