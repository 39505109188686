import { useState, FC, useEffect, useMemo, useRef, useCallback } from "react";
import { v4 } from "uuid";
import classNames from "classnames";
import Modal from "../../components/Modal";
import DropdownMenu from "../../components/DropdownMenu";
import ShipmentPiecesDetails from "./ShipmentPiecesDetails";
import AnimatedHeight from "../../components/AnimatedHeight";
import {
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ShipmentHeader from "../../components/Shipment/ShipmentHeader";
import CreateClientModal from "../../components/Account/CreateClientModal";
import UpdateClientModal from "../../components/Account/UpdateClientModal";
import ShipmentItemTable from "../../components/Shipment/ShipmentItemTable";
import {
  AddressSearch,
  BusinessUnitSearch,
} from "../../components/LocationSearch";
import {
  formatFloat,
  formatName,
  formatPhoneNumber,
  paymentModeFormat,
} from "../../utils/format";
import {
  setShipmentCreateDraft,
  setShipmentCreateDrafts,
} from "../../store/slices";
import {
  AddButton,
  LinkText,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Buttons";
import {
  FormTextInput,
  FormGroupSearch,
  FormTextAreaInput,
  FormSimpleRadioGroup,
  FormSelectOptionObjects,
} from "../../components/FormFields";
import {
  getAccount,
  loaderService,
  getPreShipment,
  getAccountWhitelist,
  getShipmentItems,
  getShipmentRates,
  getBusinessUnitByTerritory,
  getBUCredit,
  getTier,
  getWeights,
  getDeliveryDistance,
  getServicesFromPaymentMode,
  getServicesFromDeliveryType,
  getLockedServicesByTier,
  getAppLockedServices,
} from "../../services";
import {
  AccountSearch,
  BoxAccountSearch,
  filterDestination,
  AccountSearchFavoriteHistoric,
} from "../../components/Account/AccountSearch";
import {
  PaymentMode,
  discountItem,
  DeliveryType,
  ItemInterface,
  ShipmentService,
  AccountInterface,
  ShipmentInterface,
  LocationInterface,
  AccountAuthorization,
  DestinationInterface,
  ShipmentStatus,
  BusinessUnitInterface,
} from "../../interfaces";
import FormLabel from "../../components/FormLabel";
import { GoogleMap } from "@react-google-maps/api";
import LoadingIcon from "../../components/LodingIcon";
import { RangeDeclareDto, TierDto } from "../../interfaces/Dtos";
import { ADMIN_ROLES } from "../../interfaces/UserInterface";
import {
  currencyExchangeText,
  getDetailsFromCoords,
  useCurrencyExchanges,
  validateAccount,
} from "../../utils";
import { AutocompleteRegion } from "../../components/Form/FormMapsAutocomplete";
import { BUCreditDTO } from "../../interfaces/Dtos/BUCreditDTO";
import { ServiceDTO } from "../../interfaces/Dtos/ServiceDTO";

export const WhitelistOption: FC<{
  client: AccountInterface;
  location: LocationInterface;
}> = ({ client, location }) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-col">
        <p className="text-sm font-semibold leading-5 truncate">
          {formatName(client.accountFullName)}
        </p>
        {client.listAccountPhone?.length === 0 ||
        (client.abreviationName !== "V-" && client.abreviationName !== "E-") ? (
          <p className="text-sm leading-5">
            {client.abreviationName}
            {client.identificationNumber}
          </p>
        ) : (
          <p className="text-sm leading-5">
            {formatPhoneNumber(client.listAccountPhone?.[0])}
          </p>
        )}
        <p className="text-sm leading-5">{location.name}</p>
      </div>
    </div>
  );
};

enum DropdownMenuOption {
  EDIT_SHIPPER,
  EDIT_CONSIGNEE,
  CLEAN,
  DELETE,
}

interface ShipmentFormProps {
  /**
   * Indicate if show shipment data
   */
  active: boolean;
  /**
   * Shipment index
   */
  index: number;
  /**
   * Shipper is editable
   */
  shipperIsEditable?: boolean;
  disablePaymentMode?: boolean;
  /**
   * Indicate if there is an error
   */
  error?: string;
  /**
   * On delete callback
   */
  onDelete: () => void;
  /**
   * On click callback
   */
  onArrowClick: () => void;
}

const ShipmentForm: FC<ShipmentFormProps> = ({
  active,
  index,
  shipperIsEditable,
  disablePaymentMode,
  error,
  onDelete,
  onArrowClick,
}) => {
  const randomId = v4();
  const dispatch = useAppDispatch();
  const exchanges = useCurrencyExchanges();
  const loading = loaderService.useIsLoading();
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  // Constant
  const mercadoLibreUser = "mercadolibre.ve";
  // Check if error is truthy and non-empty
  const hasError = !!error && error.trim().length > 0;
  const errorLines = hasError ? error.split("\n") : [];
  // Store data
  const user = useAppSelector((state) => state.user);
  const countries = useAppSelector((state) => state.inmutable.countries);
  const maxDiscount = useAppSelector((state) => state.inmutable.maxDiscount);
  const buPaymentModes = useAppSelector((state) => state.user.paymentModes);
  const lockedBUServices = useAppSelector((state) => state.user.lockedServices);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const taxIdTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const shipmentDrafts = useAppSelector(
    (state) => state.shipmentCreate.shipmentDrafts
  );
  const shipmentDraft = shipmentDrafts[index];
  const pieceCategories = useAppSelector(
    (state) => state.inmutable.pieceCategories
  );
  const rangeMinDeclareList = useAppSelector(
    (state) => state.inmutable.rangeDeclareList
  );

  // Form data
  const rateKey = useRef<string>("");
  const itemsKey = useRef<string>("");
  const mapRef = useRef<google.maps.Map>();
  const [tier, setTier] = useState<TierDto>();
  const [isLoading, setIsLoading] = useState(0);
  const [auxReference, setAuxReference] = useState("");
  const [buCredit, setBuCredit] = useState<BUCreditDTO>();
  const [preShipmentCode, setPreShipmentCode] = useState("");
  const [whitelistSearch, setWhitelistSearch] = useState("");
  const [openCleanModal, setOpenCleanModal] = useState(false);
  const [preShipmentError, setPreShipmentError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [consigneeType, setConsigneeType] = useState("account");
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [openCreationModal, setOpenCreationModal] = useState(false);
  const [auxConsigneeType, setAuxConsigneeType] = useState("account");
  //const [disablePaymentMode, setDisablePaymentMode] = useState(false); left commented in case this would work better
  const [whitelist, setWhitelist] = useState<DestinationInterface[]>([]);
  const [openPreShipmentModal, setOpenPreShipmentModal] = useState(false);
  const [clientTypeSelected, setClientTypeSelected] = useState("shipper");
  const [shipmentItems, setShipmentItems] = useState<ItemInterface[]>([]);
  const [openPaymentModeModal, setOpenPaymentModeModal] = useState(false);
  const [allowedServices, setAllowedServices] = useState<ServiceDTO[]>([]);
  const [openConsigneeTypeModal, setOpenConsigneeTypeModal] = useState(false);
  const [paymentModeServices, setPaymentModeServices] = useState<ServiceDTO[]>(
    []
  );
  const [tierBlockedServices, setTierBlockedServices] = useState<ServiceDTO[]>(
    []
  );
  const [appBlockedServices, setAppBlockedServices] = useState<ServiceDTO[]>(
    []
  );
  const [deliveryTypeServices, setDeliveryTypeServices] = useState<
    ServiceDTO[]
  >([]);
  const [auxPaymentMode, setAuxPaymentMode] = useState<
    PaymentMode | number | string
  >();
  const [declaredValue, setDeclaredValue] = useState(
    shipmentDraft.declaredValue ?? ""
  );
  const [baseShipmentItems, setBaseShipmentItems] = useState<ItemInterface[]>(
    []
  );

  // Form errors
  const shipperRef = useRef<HTMLDivElement>(null);
  const piecesRef = useRef<HTMLDivElement>(null);
  const consigneeRef = useRef<HTMLDivElement>(null);
  const locationRef = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState({
    shipper: "",
    consignee: "",
    location: "",
    discount: "",
    declaredValue: "",
    items: "",
    pieces: false,
  });

  const saveDraft = useCallback(
    (draft: Partial<ShipmentInterface>) => {
      dispatch(setShipmentCreateDraft({ index, shipment: draft }));
    },
    [dispatch, index]
  );

  const paymentModes = useMemo(() => {
    let result = buPaymentModes
      .filter(
        (mode) =>
          mode.paymentModeID !== PaymentMode.CREDIT &&
          mode.paymentModeID !== PaymentMode.DEST_BOXOFFICE_CREDIT &&
          ((!!buCredit &&
            shipmentDraft.shipperAuth !== AccountAuthorization.ENTERPRISE) ||
            mode.paymentModeID !== PaymentMode.BOXOFFICE_CREDIT)
      )
      .map((mode) => ({
        name: paymentModeFormat(mode.paymentModeID),
        value: mode.paymentModeID,
        disabled:
          mode.paymentModeID === PaymentMode.BOXOFFICE_CREDIT &&
          (buCredit?.balance ?? 0) <= 0,
        tooltip:
          mode.paymentModeID === PaymentMode.BOXOFFICE_CREDIT &&
          (buCredit?.balance ?? 0) <= 0
            ? "La tienda usó la totalidad del crédito disponible"
            : "",
      }));

    if (
      shipmentDraft.shipperAuth === AccountAuthorization.ENTERPRISE &&
      (!shipmentDraft.shipper?.listAuthorizingAccount ||
        shipmentDraft.shipper.listAuthorizingAccount.length === 0)
    ) {
      result.push({
        name: paymentModeFormat(PaymentMode.CREDIT),
        value: PaymentMode.CREDIT,
        disabled: false,
        tooltip: "",
      });
    }

    return result;

    // You only need to know the shipperAuth and if they have a boxAccount to
    // obtain the payment methods and the auth account
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentDraft.shipperAuth, shipmentDraft.boxAccount, buCredit]);

  const dropdownMenuOptions = useMemo(() => {
    const { shipper, consignee } = shipmentDraft;
    const options = [];

    if (
      !!shipper &&
      shipperIsEditable &&
      !shipper?.agreementID &&
      (shipper?.listAuthorizingAccount?.length ?? 0) === 0
    ) {
      options.push({
        title: "Editar Remitente",
        value: DropdownMenuOption.EDIT_SHIPPER,
      });
    }
    if (
      !!consignee &&
      !consignee?.agreementID &&
      (consignee?.listAuthorizingAccount?.length ?? 0) === 0
    ) {
      options.push({
        title: "Editar Destinatario",
        value: DropdownMenuOption.EDIT_CONSIGNEE,
      });
    }
    if (index > 0 || shipperIsEditable) {
      options.push({ title: "Limpiar Guía", value: DropdownMenuOption.CLEAN });
    }
    if (index > 0) {
      options.push({ title: "Eliminar", value: DropdownMenuOption.DELETE });
    }

    return options;
  }, [index, shipperIsEditable, shipmentDraft]);

  const paymentModesWithAuth = useMemo(() => {
    const result = [
      ...paymentModes,
      ...(shipmentDraft.shipper?.listAuthorizingAccount ?? []),
    ].map((option) => {
      if ("value" in option) {
        return option;
      } else {
        return {
          name:
            "Crédito de " +
            (!option.accountSiteID
              ? formatName(option.accountFullName)
              : ` ${option.accountSiteName} (${option.accountSiteCode})`),
          value: option,
          disabled: false,
          tooltip: "",
        };
      }
    });

    return result;
  }, [paymentModes, shipmentDraft.shipper?.listAuthorizingAccount]);

  const availableServiceTypes = useMemo(() => {
    if (consigneeType === "locker") {
      return [
        {
          name: "Casillero Nacional",
          value: ShipmentService.NATIONAL_LOCKER,
        },
      ];
    } else if (shipmentDraft.deliveryType === DeliveryType.INTERNATIONAL) {
      return [
        {
          name: "Envío Internacional",
          value: ShipmentService.INTERNATIONAL,
        },
      ];
    } else {
      return allowedServices.map((service) => {
        return {
          name: service.serviceShortName,
          value: service.serviceID,
        };
      });
    }
  }, [consigneeType, shipmentDraft.deliveryType, allowedServices]);

  const piecesRestrictions = useMemo(() => {
    if (
      shipmentDraft.shipper?.abreviationName === "J-" &&
      shipmentDraft.shipper?.identificationNumber === "306842675"
    ) {
      return {
        maxSumWeight: 10,
      };
    }

    return {
      maxSumWeight: undefined,
    };
  }, [shipmentDraft.shipper]);

  const clean = () => {
    //setDisablePaymentMode(false);
    setDeclaredValue("");
    setBaseShipmentItems([]);
    setShipmentItems([]);
    setErrors({
      shipper: "",
      consignee: "",
      location: "",
      discount: "",
      declaredValue: "",
      items: "",
      pieces: false,
    });
    saveDraft({
      id: undefined,
      document: undefined,
      declaredValue: "",
      preShipmentHeaderID: undefined,
      shipper: index > 0 ? shipmentDraft.shipper : undefined,
      consignee: undefined,
      consigneeAddress: undefined,
      buConsignee: undefined,
      pieces: [],
      items: [],
      buSource: user.businessUnit,
      paymentMode: undefined,
      discount: undefined,
      status: ShipmentStatus.DRAFT,
      accountSiteID: undefined,
      accountBillTo: undefined,
      boxAccount: undefined,
      isToPickup: undefined,
    });
  };

  const deleteDraft = () => {
    const newDrafts = [...shipmentDrafts];
    newDrafts.splice(index, 1);
    dispatch(setShipmentCreateDrafts(newDrafts));
    onDelete();
  };

  const handleDropdownMenuSelect = (option: { value: DropdownMenuOption }) => {
    if (option.value === DropdownMenuOption.EDIT_SHIPPER) {
      setClientTypeSelected("shipper");
      setOpenUpdateModal(true);
    } else if (option.value === DropdownMenuOption.EDIT_CONSIGNEE) {
      setClientTypeSelected("consignee");
      setOpenUpdateModal(true);
    } else if (option.value === DropdownMenuOption.CLEAN) {
      setOpenCleanModal(true);
    } else if (option.value === DropdownMenuOption.DELETE) {
      setOpenDeleteModal(true);
    }
  };

  const handlePaymentModeChange = async (
    option?: PaymentMode | number | string
  ) => {
    if (!option) return;

    let paymentMode: PaymentMode;
    let accountBillTo: AccountInterface | undefined;
    let accountSiteID: number | undefined;

    if (option === PaymentMode.COD) {
      paymentMode = PaymentMode.COD;
      accountBillTo = shipmentDraft.consignee;
      accountSiteID = undefined;
    } else if (option === PaymentMode.CONTADO) {
      paymentMode = PaymentMode.CONTADO;
      accountBillTo = shipmentDraft.shipper;
      accountSiteID = undefined;
    } else if (option === PaymentMode.BOXOFFICE_CREDIT) {
      paymentMode = PaymentMode.BOXOFFICE_CREDIT;
      accountBillTo = shipmentDraft.shipper;
      accountSiteID = undefined;
    } else {
      paymentMode = PaymentMode.CREDIT;
      accountBillTo = paymentModesWithAuth.find(
        (p) =>
          typeof p.value === "object" &&
          `${p.value.id}-${p.value.accountSiteID}` === option
      )?.value! as AccountInterface;
      accountSiteID = accountBillTo.accountSiteID ?? undefined;
    }

    saveDraft({
      accountBillTo,
      paymentMode,
      accountSiteID,
    });
  };

  const handlePreShipmentSubmit = async () => {
    if (!preShipmentCode) {
      setPreShipmentError("Ingrese un código de Pre-Guía");
      return;
    }

    loaderService.start();
    const preShipmentMessage = await getPreShipment(
      preShipmentCode,
      pieceCategories.find((p) => p.code === "0490")!
    );
    loaderService.stop();

    if (preShipmentMessage.didError || preShipmentMessage.model === null) {
      setPreShipmentError(preShipmentMessage.errorMessage);
      return;
    }

    const preShipment = preShipmentMessage.model;
    const shipper = await getAccount(preShipment?.shipper?.id ?? "");
    const consignee = await getAccount(preShipment?.consignee?.id ?? "");

    // Verify account bill to
    let accountBillTo: AccountInterface | undefined;
    const paymentMode = preShipment.paymentMode!;
    if (!preShipment.accountBillTo) {
      if (paymentMode === PaymentMode.COD || paymentMode === PaymentMode.DEST_BOXOFFICE_CREDIT) {
        accountBillTo = consignee ?? undefined;
      } else if (paymentMode === PaymentMode.CONTADO || paymentMode === PaymentMode.BOXOFFICE_CREDIT) {
        accountBillTo = shipper ?? undefined;
      } else {
        setPreShipmentError("La pre-guía es de crédito pero no tiene cuenta a la cual facturar.");
        return;
      }
    } else {
      accountBillTo = await getAccount(
        preShipment?.accountBillTo?.id ?? ""
      ) ?? undefined;
    }

    const buConsignee = businessUnits.find(
      (bu) => bu.code === preShipment?.buConsignee?.code
    );


    // Rewrite declared value of pieces to 0 if the payment mode is credit and the creation user is mercado libre
    if (
      preShipment.paymentMode === PaymentMode.CREDIT &&
      preShipment.creationUser === mercadoLibreUser
    ) {
      if (!!preShipment.pieces && preShipment.pieces?.length > 0) {
        for (let i = 0; i < preShipment.pieces.length; i++) {
          preShipment.pieces[i].value = 0;
        }
      }
    }
    /*if (
      preShipment.paymentMode === PaymentMode.CREDIT ||
      preShipment.paymentMode === PaymentMode.COD
    ) {
      setDisablePaymentMode(true);
    }*/

    if (preShipment.deliveryType === DeliveryType.AT_OFFICE) {
      preShipment.consigneeAddress = buConsignee?.location;
    } else if (
      preShipment.deliveryType === DeliveryType.AT_HOME &&
      preShipment.consigneeAddress?.coordinates?.lat !== 0 &&
      preShipment.consigneeAddress?.coordinates?.lng !== 0
    ) {
      getDetailsFromCoords(
        preShipment.consigneeAddress?.coordinates?.lat!,
        preShipment.consigneeAddress?.coordinates?.lng!,
        mapRef,
        (location) => {
          const reference = preShipment.consigneeAddress?.name;
          preShipment.consigneeAddress = location;
          preShipment.consigneeAddress.reference = reference;

          const draft: Partial<ShipmentInterface> = {
            ...preShipment,
            id: undefined,
            buConsignee: buConsignee ?? undefined,
            shipper: shipper ?? undefined,
            consignee: consignee ?? undefined,
            accountBillTo: accountBillTo,
            preShipmentHeaderID: preShipment.id,
          };
          saveDraft(draft);
          setOpenPreShipmentModal(false);
        }
      );
      return;
    } else if (
      preShipment.deliveryType === DeliveryType.AT_HOME &&
      preShipment.consigneeAddress
    ) {
      setAuxReference(preShipment.consigneeAddress.name);
      preShipment.consigneeAddress = undefined;
    }

    const draft: Partial<ShipmentInterface> = {
      ...preShipment,
      id: undefined,
      buConsignee: buConsignee ?? undefined,
      shipper: shipper ?? undefined,
      consignee: consignee ?? undefined,
      accountBillTo: accountBillTo,
      preShipmentHeaderID: preShipment.id,
    };
    saveDraft(draft);
    setOpenPreShipmentModal(false);

  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 1) {
      event.target.value = event.target.value.replace(/^0+/, "");
    }
    const setValue = (value: string) => {
      event.target.value = value;
    };

    if (!formatFloat(event.target.value, setValue)) {
      return false;
    }
    return true;
  };

  const minDeclaredValue = (weight: number, rangeList: RangeDeclareDto[]) => {
    return (
      rangeList.find((r) => r.minWeight < weight && weight <= r.maxWeight)
        ?.minDeclareValue ?? 0
    );
  };

  const verifyDeclaredValue = useCallback(
    (value: string) => {
      if (
        shipmentDraft.boxAccount ||
        shipmentDraft.service !== ShipmentService.STANDARD ||
        (shipmentDraft.paymentMode !== PaymentMode.COD &&
          shipmentDraft.paymentMode !== PaymentMode.CONTADO)
      ) {
        return true;
      }

      const minVal = minDeclaredValue(
        shipmentDraft.totalChargedWeight ?? 0,
        rangeMinDeclareList
      );

      if (parseFloat(value ? value : "0") < minVal) {
        setErrors((errors) => ({
          ...errors,
          declaredValue: `El valor declarado no puede ser menor a ${minVal.toFixed(
            2
          )}$`,
        }));
        return false;
      } else {
        setErrors((errors) => ({
          ...errors,
          declaredValue: "",
        }));
        return true;
      }
    },
    [shipmentDraft, rangeMinDeclareList]
  );

  const handleDeclaredValueChange = () => {
    const value = parseFloat(declaredValue ? declaredValue : "0");

    if (!verifyDeclaredValue(declaredValue ? declaredValue : "0")) {
      return;
    }

    saveDraft({
      declaredValue,
      pieces: shipmentDraft.pieces!.map((piece) => ({
        ...piece,
        value: value / shipmentDraft.pieces!.length,
      })),
    });
  };

  // Get shipper auth
  useEffect(() => {
    const setShipperAuth = (auth: AccountAuthorization) => {
      saveDraft({ shipperAuth: auth });
    };

    if (!shipmentDraft.shipper) {
      setShipperAuth(AccountAuthorization.NO_AUTHORIZED);
      return;
    }

    if (
      !!shipmentDraft.shipper.agreementID ||
      shipmentDraft.shipper.listAuthorizingAccount.length > 0
    ) {
      saveDraft({
        shipperAuth: AccountAuthorization.ENTERPRISE,
      });

      return;
    } else {
      saveDraft({
        shipperAuth: AccountAuthorization.ENTERPRISE,
      });
    }

    // Check if there are some autorizers companies
    if (shipmentDraft.shipper.listAuthorizingAccount.length === 0) {
      setShipperAuth(AccountAuthorization.NO_AUTHORIZED);
      return;
    }

    // If there is no elements in the shippers listAuthorizingAccount then, set AUTHORIZED
    setShipperAuth(AccountAuthorization.AUTHORIZED);
  }, [shipmentDraft.shipper, saveDraft]);

  useEffect(() => {
    if (!!shipmentDraft.boxAccount) {
      setConsigneeType("locker");
    }
  }, [shipmentDraft.boxAccount]);

  // Get consignee auth
  useEffect(() => {
    const setConsigneeAuth = (auth: AccountAuthorization) => {
      saveDraft({ consigneeAuth: auth });
    };

    if (!shipmentDraft.consignee) {
      setConsigneeAuth(AccountAuthorization.NO_AUTHORIZED);
      return;
    }

    // Check if there are some autorizers companies
    if (!!shipmentDraft.consignee.agreementID) {
      setConsigneeAuth(AccountAuthorization.ENTERPRISE);
      return;
    }

    // If there is no elements in the shippers listAuthorizingAccount then, set AUTHORIZED
    if (shipmentDraft.consignee.listAuthorizingAccount.length === 0) {
      setConsigneeAuth(AccountAuthorization.NO_AUTHORIZED);
      return;
    }

    setConsigneeAuth(AccountAuthorization.AUTHORIZED);
  }, [shipmentDraft.consignee, saveDraft]);

  // Get whitelist from selected shipper
  useEffect(() => {
    let canceled = false;

    const getLocations = async () => {
      if (
        !shipmentDraft.accountBillTo ||
        shipmentDraft.shipperAuth === AccountAuthorization.NO_AUTHORIZED ||
        (!shipmentDraft.accountBillTo.agreementID &&
          (shipmentDraft.accountBillTo.listAuthorizingAccount ?? []).length ===
            0)
      ) {
        setWhitelist([]);
        return;
      }

      const response = await getAccountWhitelist(
        taxIdTypes,
        shipmentDraft.accountBillTo.id,
        shipmentDraft.accountBillTo?.agreementID ?? undefined,
        false // isLocked = false - Only get whitelisted destinations
      );
      if (canceled) return;

      if (response.didError || !response.model) {
        setErrors((errors) => ({
          ...errors,
          consignee: response.errorMessage,
        }));
        setWhitelist([]);
        return;
      }

      setWhitelist(response.model.consigneeDestinationList);
    };

    getLocations();

    return () => {
      canceled = true;
    };
  }, [shipmentDraft.accountBillTo, shipmentDraft.shipperAuth, taxIdTypes]);

  // Get whitelist text from selected consignee
  useEffect(() => {
    setWhitelistSearch(
      formatName(shipmentDraft.consignee?.accountFullName ?? "")
    );
  }, [shipmentDraft.consignee]);

  // Get shipment items
  useEffect(() => {
    const { totalChargedWeight, declaredValue, deliveryDistance } =
      shipmentDraft;
    if (
      !totalChargedWeight ||
      deliveryDistance === undefined ||
      (shipmentDraft.service !== ShipmentService.DOCUMENT &&
        shipmentDraft.paymentMode !== PaymentMode.CREDIT &&
        (!declaredValue || isNaN(Number(declaredValue)))) ||
      !shipmentDraft.buSource?.location?.coordinates.lat ||
      !shipmentDraft.buSource?.location?.coordinates.lng ||
      !shipmentDraft.consigneeAddress?.coordinates.lat ||
      !shipmentDraft.consigneeAddress?.coordinates.lng ||
      !tier
    ) {
      return;
    }

    setErrors((errors) => ({
      ...errors,
      items: "",
    }));

    const getAllShipmentItems = async () => {
      const key = v4();
      itemsKey.current = key;

      loaderService.start();
      setIsLoading((p) => p + 1);
      const response = await getShipmentItems({
        CurrencyID: 2,
        CountryID: 236,
        Weight: totalChargedWeight,
        DeclaratedValue: declaredValue ? Number(declaredValue) : 0,
        Distance: deliveryDistance,
        ApplicationID: applicationID,
        ShipperAccountID: shipmentDraft.shipper?.id,
        ConsigneeAccountID: shipmentDraft.consignee?.id,
        AccountBillToID: shipmentDraft.accountBillTo?.id,
        AccountSiteID: shipmentDraft.accountBillTo?.accountSiteID ?? undefined,
        ServiceID: shipmentDraft.service,
        PaymentModeID: shipmentDraft.paymentMode,
        DeliveryTypeID: shipmentDraft.deliveryType,
        SRM: !!shipmentDraft.pieces?.reduce((acc, p) => acc + p.value, 0),
        TierID: tier?.tierID,
        ShippingMethodID: 10,
        PackageTypeID: 10,
        SalesDate: new Date().toISOString(),
        BUCodeSource: shipmentDraft.buSource?.code,
        BUCodeConsignee: shipmentDraft.buConsignee?.code,
        IsToPickup: shipmentDraft.isToPickup,
      });
      setIsLoading((p) => p - 1);
      loaderService.stop();

      if (!response || key !== itemsKey.current) return;
      if (response.didError || !response.model) {
        setErrors((errors) => ({
          ...errors,
          items: response.errorMessage,
        }));
        return;
      }
      itemsKey.current = "";

      // Filter ipostel and discount
      setBaseShipmentItems(
        response.model.filter(
          (item) =>
            !item.name.toLowerCase().includes("ipostel") &&
            item.id !== discountItem.id
        )
      );
    };

    getAllShipmentItems();
  }, [
    tier,
    shipmentDraft.shipper?.id,
    shipmentDraft.consignee?.id,
    shipmentDraft.service,
    shipmentDraft.paymentMode,
    shipmentDraft.deliveryType,
    shipmentDraft.pieces,
    shipmentDraft.totalChargedWeight,
    shipmentDraft.declaredValue,
    shipmentDraft.deliveryDistance,
    shipmentDraft.buSource,
    shipmentDraft.consigneeAddress,
    applicationID,
  ]);

  useEffect(() => {
    if (!shipmentDraft.pieces || shipmentDraft.pieces.length === 0) {
      saveDraft({
        totalChargedWeight: 0,
        totalPhysicalWeight: 0,
        totalDimensionalWeight: 0,
      });
      return;
    }

    const getShipmentWeight = async () => {
      const weights = await getWeights(
        236,
        10,
        shipmentDraft.shipper?.id,
        shipmentDraft.service,
        shipmentDraft.paymentMode,
        shipmentDraft.pieces ?? []
      );

      if (!weights || weights.didError) return;

      saveDraft({
        totalChargedWeight: weights.model?.chargedWeight,
        totalPhysicalWeight: weights.model?.physicalWeight,
        totalDimensionalWeight: weights.model?.dimensionalWeight,
      });
    };

    getShipmentWeight();
  }, [shipmentDraft.pieces, saveDraft]);

  useEffect(() => {
    if (
      !shipmentDraft.buSource?.location?.coordinates.lat ||
      !shipmentDraft.buSource?.location?.coordinates.lng ||
      !shipmentDraft.consigneeAddress?.coordinates.lat ||
      !shipmentDraft.consigneeAddress?.coordinates.lng
    ) {
      saveDraft({
        deliveryDistance: 0,
      });
      return;
    }

    const getShipmentDeliveryDistance = async () => {
      const distance = await getDeliveryDistance(
        shipmentDraft.buConsignee?.location?.coordinates.lat!,
        shipmentDraft.buConsignee?.location?.coordinates.lng!,
        shipmentDraft.consigneeAddress?.coordinates.lat!,
        shipmentDraft.consigneeAddress?.coordinates.lng!
      );

      if (!distance || distance.didError) return;

      saveDraft({ deliveryDistance: distance.model?.deliveryDistance });
    };

    getShipmentDeliveryDistance();
  }, [shipmentDraft.buSource, shipmentDraft.consigneeAddress, saveDraft]);

  // Get shipment items rate
  useEffect(() => {
    setErrors((errors) => ({
      ...errors,
      items: "",
    }));

    const { pieces, buSource, consigneeAddress, shipper } = shipmentDraft;
    if (
      !pieces ||
      !shipper ||
      !buSource ||
      !consigneeAddress ||
      pieces.length === 0 ||
      baseShipmentItems.length === 0
    ) {
      setShipmentItems([]);
      saveDraft({ items: [] });
      return;
    }

    const getAllShipmentItems = async () => {
      const key = v4();
      rateKey.current = key;

      loaderService.start();
      setIsLoading((p) => p + 1);
      let response = await getShipmentRates(
        shipmentDraft.service ?? ShipmentService.STANDARD,
        shipmentDraft.paymentMode ?? PaymentMode.COD,
        shipmentDraft.deliveryType ?? DeliveryType.AT_OFFICE,
        buSource.code ?? "",
        shipmentDraft.buConsignee?.code ?? "",
        shipmentDraft.shipper?.id,
        shipmentDraft.consignee?.id,
        shipmentDraft.accountBillTo?.id,
        buSource.location,
        consigneeAddress,
        baseShipmentItems,
        pieces,
        pieces.reduce((acc, p) => acc + p.value, 0) > 0,
        new Date().toISOString(),
        applicationID,
      );
      setIsLoading((p) => p - 1);
      loaderService.stop();

      if (!response || key !== rateKey.current) {
        return;
      }
      if (response.didError || !response.model) {
        setErrors((errors) => ({
          ...errors,
          items: response.errorMessage,
        }));
        return;
      }
      rateKey.current = "";

      setShipmentItems(response.model.items);
      saveDraft({
        items: response.model.items
          .filter(
            (item) =>
              item.mandatory ||
              shipmentDraft.items?.some((s) => s.id === item.id)
          )
          .sort((a, b) => a.order - b.order),
      });
    };

    getAllShipmentItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseShipmentItems,
    shipmentDraft.shipper,
    shipmentDraft.buConsignee,
    shipmentDraft.consigneeAddress,
    shipmentDraft.accountBillTo?.id,
    saveDraft,
  ]);

  // Form data dependencies

  useEffect(() => {
    const buSource = shipmentDraft.buSource;
    const userBU = user.businessUnit;

    if (!!userBU && (!buSource || buSource.id !== userBU.id)) {
      saveDraft({ buSource: userBU });
    } else if (!!buSource && !userBU) {
      saveDraft({ buSource: undefined });
    }
  }, [shipmentDraft.buSource, user.businessUnit, saveDraft]);

  useEffect(() => {
    if (consigneeType === "locker") {
      saveDraft({ service: ShipmentService.NATIONAL_LOCKER });
    } else if (shipmentDraft.deliveryType === DeliveryType.INTERNATIONAL) {
      saveDraft({ service: ShipmentService.INTERNATIONAL });
    } else {
      saveDraft({ service: ShipmentService.STANDARD });
    }
  }, [
    consigneeType,
    shipmentDraft.paymentMode,
    saveDraft,
    shipmentDraft.deliveryType,
  ]);

  useEffect(() => {
    if (openPreShipmentModal) {
      setPreShipmentError("");
      setPreShipmentCode("");
    }
  }, [openPreShipmentModal]);

  useEffect(() => {
    if (!shipmentDraft.items) return;

    const absoluteTotal = shipmentDraft.items
      .filter((item) => !item.rate.isPercentage)
      .reduce((total, item) => total + item.rate.value, 0);
    const percentageTotal = shipmentDraft.items
      .filter((item) => item.rate.isPercentage)
      .reduce((total, item) => total + item.rate.value, 0);

    const total = absoluteTotal * (1 + percentageTotal / 100);

    saveDraft({ total });
  }, [shipmentDraft.items, saveDraft]);

  useEffect(() => {
    if (error?.includes("Remitente inválido")) {
      setClientTypeSelected("shipper");
      setOpenUpdateModal(true);
    } else if (error?.includes("Destinatario inválido")) {
      setClientTypeSelected("consignee");
      setOpenUpdateModal(true);
    }
  }, [error]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getCredit = async () => {
      const response = await getBUCredit(buCode);
      if (!response.didError && response.model) {
        setBuCredit(response.model);
      }
    };

    getCredit();
  }, [user.businessUnit]);

  useEffect(() => {
    const deliveryType = shipmentDraft.deliveryType;
    if (deliveryType === undefined) {
      setDeliveryTypeServices([]);
      return;
    }

    const getDeliveryTypeServices = async () => {
      const services = await getServicesFromDeliveryType(deliveryType);

      if (!services.didError && services.model !== null) {
        setDeliveryTypeServices(services.model);
      }
    };

    getDeliveryTypeServices();
  }, [shipmentDraft.deliveryType]);

  useEffect(() => {
    const accounBillToID = shipmentDraft.accountBillTo?.id;
    const shipperLatitude = shipmentDraft.buSource?.location.coordinates.lat;
    const shipperLongitude = shipmentDraft.buSource?.location.coordinates.lng;
    const consigneeLatitude = shipmentDraft.consigneeAddress?.coordinates.lat;
    const consigneeLongitude = shipmentDraft.consigneeAddress?.coordinates.lng;
    if (
      !accounBillToID ||
      !shipperLatitude ||
      !shipperLongitude ||
      !consigneeLatitude ||
      !consigneeLongitude
    ) {
      setTier(undefined);
      setTierBlockedServices([]);
      return;
    }

    const getShipmentTier = async () => {
      const tier = await getTier({
        AccountBillToID: accounBillToID,
        LatitudeShipperAddress: shipperLatitude,
        LongitudeShipperAddress: shipperLongitude,
        LatitudeConsigneeAddress: consigneeLatitude,
        LongitudeConsigneeAddress: consigneeLongitude,
      });

      if (tier.didError || !tier.model) {
        return;
      }

      const blockedServices = await getLockedServicesByTier(tier.model.tierID);

      if (!blockedServices.didError && blockedServices.model) {
        setTierBlockedServices(blockedServices.model);
      } else {
        setTierBlockedServices([]);
      }

      setTier(tier.model);
    };

    getShipmentTier();
  }, [
    shipmentDraft.accountBillTo?.id,
    shipmentDraft.buSource?.location.coordinates.lat,
    shipmentDraft.buSource?.location.coordinates.lng,
    shipmentDraft.consigneeAddress?.coordinates.lat,
    shipmentDraft.consigneeAddress?.coordinates.lng,
  ]);

  useEffect(() => {
    const paymentMode = shipmentDraft.paymentMode;
    if (paymentMode === undefined) {
      setPaymentModeServices([]);
      return;
    }

    const getPaymentModeServices = async () => {
      const services = await getServicesFromPaymentMode(paymentMode);

      if (!services.didError && services.model !== null) {
        setPaymentModeServices(services.model);
      }
    };

    getPaymentModeServices();
  }, [shipmentDraft.paymentMode]);

  useEffect(() => {
    if (applicationID === undefined) {
      setAppBlockedServices([]);
      return;
    }

    const getLockedServices = async () => {
      const services = await getAppLockedServices(applicationID);

      if (!services.didError && services.model !== null) {
        setAppBlockedServices(services.model);
      }
    };

    getLockedServices();
  }, [applicationID]);

  useEffect(() => {
    const accountSiteID = shipmentDraft?.accountSiteID;
    const authorizedServices =
      shipmentDraft.paymentMode === PaymentMode.CREDIT && accountSiteID
        ? shipmentDraft.shipper?.listAuthorizingAccount
            .filter((a) => a.accountSiteID === accountSiteID)
            .map((a) => a.serviceID)
        : undefined;

    const allowedServices = paymentModeServices.filter(
      (service) =>
        deliveryTypeServices.some((s) => s.serviceID === service.serviceID) &&
        !tierBlockedServices.some((s) => s.serviceID === service.serviceID) &&
        (service.receptionInBU ||
          shipmentDraft.deliveryType !== DeliveryType.AT_OFFICE) &&
        !lockedBUServices.some((s) => s.serviceID === service.serviceID) &&
        !appBlockedServices.some((s) => s.serviceID === service.serviceID) &&
        (!authorizedServices ||
          authorizedServices.includes(service.serviceID))
    )
    setAllowedServices(allowedServices);

    if (allowedServices.length === 1) {
      saveDraft({ service: allowedServices[0].serviceID });
    }
  }, [
    saveDraft,
    paymentModeServices,
    deliveryTypeServices,
    tierBlockedServices,
    appBlockedServices,
    shipmentDraft.deliveryType,
    shipmentDraft.shipper,
    shipmentDraft.paymentMode,
    shipmentDraft.accountSiteID,
    lockedBUServices,
  ]);

  return (
    <div className="flex flex-1 flex-col gap-8">
      {/* Shipper and Consignee description */}
      <div>
        <div
          className={classNames(
            "transition-full duration-1000 ease-in-out flex flex-1 items-center pl-2 pr-2 bg-white rounded-md border ",
            active ? "py-6" : "py-2",
            !!error && "border-red-500"
          )}
        >
          <div
            title="Menú de opciones"
            data-te-toggle="tooltip"
            onClick={() => {
              setOpenDropdownMenu((p) => !p);
            }}
            className="rounded-full hover:cursor-pointer p-4"
          >
            <EllipsisVerticalIcon
              className="h-5 w-5 text-gray3-600"
              aria-hidden="true"
            />
          </div>

          <DropdownMenu
            open={openDropdownMenu}
            setOpen={setOpenDropdownMenu}
            onSelect={handleDropdownMenuSelect}
            options={dropdownMenuOptions}
          />

          <div className="flex flex-1 pr-4">
            <ShipmentHeader
              active={active}
              buSource={shipmentDraft.buSource}
              total={shipmentDraft.total}
              shipper={shipmentDraft.shipper}
              isShipperEnterprise={
                !!shipmentDraft.shipper?.agreementID ||
                (shipmentDraft.shipper?.listAuthorizingAccount?.length ?? 0) > 0
              }
              buConsignee={shipmentDraft.buConsignee}
              consignee={shipmentDraft.consignee}
              isConsigneeEnterprise={
                !!shipmentDraft.consignee?.agreementID ||
                (shipmentDraft.consignee?.listAuthorizingAccount?.length ?? 0) >
                  0
              }
              location={shipmentDraft.consigneeAddress}
              boxAccount={shipmentDraft.boxAccount}
              onClick={onArrowClick}
            />
          </div>
        </div>
        {hasError &&
          errorLines.map((line, index) => (
            <span key={index} className="text-sm text-red-500 block">
              {line}
            </span>
          ))}
      </div>

      {/* Body */}
      <AnimatedHeight active={active} duration={1000}>
        <div className="flex flex-1 flex-col bg-white rounded-lg border px-8 py-6 gap-6">
          <div className="flex flex-1 2xl:flex-row flex-col gap-4">
            {/* Search shipper */}
            <div className="relative flex flex-1" ref={shipperRef}>
              <AccountSearch
                title="Remitente"
                selected={shipmentDraft.shipper}
                error={errors.shipper}
                disabled={!shipperIsEditable}
                placeholder="Buscar remitente..."
                ignoredAccounts={[
                  { abreviationName: "J-", identificationNumber: "306842675" },
                ]}
                onSelectClient={async (shipper) => {
                  const validations = await validateAccount(shipper, countries);

                  saveDraft({
                    shipper,
                    buConsignee: undefined,
                    consigneeAddress: undefined,
                    boxAccount: undefined,
                    paymentMode: undefined,
                    deliveryType: DeliveryType.AT_OFFICE,
                    //preShipmentHeaderID: undefined,
                  });
                  setErrors((errors) => ({ ...errors, shipper: "" }));

                  if (!!shipper && validations.error) {
                    setClientTypeSelected("shipper");
                    setOpenUpdateModal(true);
                  }
                }}
                openCreationModal={() => {
                  setOpenCreationModal(true);
                }}
              />

              <LinkText
                text="Cargar Pre-Guía"
                className="absolute top-0 right-0 text-sm"
                onClick={() => setOpenPreShipmentModal(true)}
              />
            </div>

            {/* Search consignee */}
            <div className="flex flex-1 flex-col" ref={consigneeRef}>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-m font-medium  text-gray-900 truncate">
                  Destinatario
                </label>

                <FormSimpleRadioGroup
                  horizontal
                  name={`${randomId}-consigneeType`}
                  disabled={!shipmentDraft.shipper || !shipperIsEditable}
                  selected={consigneeType}
                  onSelectOption={(option) => {
                    if (
                      shipmentDraft.service === ShipmentService.DOCUMENT &&
                      shipmentDraft.pieces!.length > 0
                    ) {
                      setAuxConsigneeType(option);
                      setOpenConsigneeTypeModal(true);
                      return;
                    }

                    saveDraft({
                      buConsignee: undefined,
                      consignee: undefined,
                      consigneeAddress: undefined,
                      boxAccount: undefined,
                      deliveryType: DeliveryType.AT_OFFICE,
                    });
                    setConsigneeType(option);
                  }}
                  options={[
                    { value: "account", name: "Persona" },
                    { value: "locker", name: "Casillero" },
                  ]}
                />
              </div>

              <div className="flex flex-1">
                {consigneeType === "locker" ? (
                  <BoxAccountSearch
                    selected={shipmentDraft.boxAccount}
                    shipperAccount={shipmentDraft.shipper}
                    error={errors.consignee}
                    setBoxAccount={async (
                      boxAccount,
                      consignee,
                      businessUnit
                    ) => {
                      const validations = await validateAccount(
                        consignee,
                        countries
                      );

                      saveDraft({
                        boxAccount,
                        accountBillTo: consignee,
                        accountSiteID: undefined,
                        consignee,
                        buConsignee: businessUnit,
                        consigneeAddress: businessUnit.location,
                        deliveryType: DeliveryType.AT_OFFICE,
                        paymentMode: PaymentMode.CREDIT,
                      });
                      setErrors((errors) => ({ ...errors, consignee: "" }));

                      if (!!consignee && validations.error) {
                        setClientTypeSelected("consignee");
                        setOpenUpdateModal(true);
                      }
                    }}
                  />
                ) : whitelist.length > 0 ? (
                  <div className="flex flex-1 flex-col">
                    <div className="relative w-full" id={`input-whitelist`}>
                      <FormGroupSearch
                        label=""
                        error={errors.consignee}
                        value={whitelistSearch}
                        disabled={!shipmentDraft.shipper}
                        placeholder="Buscar destinatario permitido..."
                        optionGroups={[
                          {
                            title: "Destinatarios Permitidos",
                            options: whitelist,
                          },
                        ]}
                        onChange={(event) =>
                          setWhitelistSearch(event.target.value)
                        }
                        filter={(option, value) =>
                          filterDestination(
                            value,
                            option.client,
                            option.location
                          )
                        }
                        onChangeFocus={(focus) => {
                          if (!focus && shipmentDraft.consignee) {
                            setWhitelistSearch(
                              formatName(
                                shipmentDraft.consignee.accountFullName
                              )
                            );
                          }
                        }}
                        onSelectOption={async (destination) => {
                          const validations = await validateAccount(
                            destination.client,
                            countries
                          );

                          saveDraft({
                            buConsignee: destination.businessUnit,
                            consignee: destination.client,
                            consigneeAddress: destination.location,
                            deliveryType: destination.homeDelivery
                              ? DeliveryType.AT_HOME
                              : DeliveryType.AT_OFFICE,
                          });
                          setErrors((errors) => ({
                            ...errors,
                            consignee: "",
                            location: "",
                          }));

                          if (!!destination.client && validations.error) {
                            setClientTypeSelected("consignee");
                            setOpenUpdateModal(true);
                          }
                        }}
                        RenderOption={({ option }) => (
                          <WhitelistOption {...option} />
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <AccountSearchFavoriteHistoric
                    title=""
                    shipperId={shipmentDraft.shipper?.id}
                    selected={shipmentDraft.consignee}
                    disabled={!shipmentDraft.shipper}
                    error={errors.consignee}
                    placeholder="Buscar destinatario..."
                    onSelectClient={async (client) => {
                      const validations = await validateAccount(
                        client,
                        countries
                      );

                      saveDraft({ consignee: client });
                      setErrors((errors) => ({ ...errors, consignee: "" }));

                      if (!!client && validations.error) {
                        setClientTypeSelected("consignee");
                        setOpenUpdateModal(true);
                      }
                    }}
                    onSelectDestination={async (destination) => {
                      let businessUnit: BusinessUnitInterface;

                      if (destination.deliveryTypeID === DeliveryType.AT_OFFICE) {
                        businessUnit = businessUnits.find(
                          (bu) => bu.code === destination.buCode
                        )!;
                      } else {
                        businessUnit = await getBusinessUnitByTerritory(
                          destination.longitude,
                          destination.latitude
                        ).then((response) => response.model!);
                      }

                      saveDraft({
                        buConsignee: businessUnit,
                        consignee:
                          (await getAccount(destination.consigneeAccountID)) ??
                          undefined,
                        consigneeAddress: {
                          name: destination.addressName,
                          address: destination.addressLine1,
                          reference: destination.addressLandMark,
                          postalCode: destination.postalCode,
                          coordinates: {
                            lat: destination.latitude,
                            lng: destination.longitude,
                          },
                        },
                        deliveryType: destination.deliveryTypeID,
                      });
                      setErrors((errors) => ({ ...errors, location: "" }));
                    }}
                    openCreationModal={() => {
                      setClientTypeSelected("consignee");
                      setOpenCreationModal(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Search destination */}
          <div
            className={classNames(
              !!shipmentDraft.shipper &&
                !!shipmentDraft.consignee &&
                !shipmentDraft.boxAccount &&
                whitelist.length === 0
                ? "flex"
                : "hidden"
            )}
          >
            <div className="flex flex-1 2xl:flex-row flex-col gap-4">
              <div className="flex flex-1 flex-col" ref={locationRef}>
                <div className="flex items-center justify-between mb-2">
                  <label className="block text-m font-medium  text-gray-900 truncate">
                    Destino
                  </label>

                  <FormSimpleRadioGroup
                    horizontal
                    name={`${randomId}-locationType`}
                    disabled={
                      !shipmentDraft.shipper ||
                      !!shipmentDraft.boxAccount ||
                      !shipmentDraft.consignee
                    }
                    selected={shipmentDraft.deliveryType}
                    onSelectOption={(option) => {
                      saveDraft({
                        consigneeAddress: undefined,
                        buConsignee: undefined,
                        deliveryType: option,
                      });
                    }}
                    options={[
                      { value: DeliveryType.AT_OFFICE, name: "Tienda" },
                      { value: DeliveryType.AT_HOME, name: "Domicilio" },
                      // {
                      //   value: DeliveryType.INTERNATIONAL,
                      //   name: "Internacional",
                      // },
                    ]}
                  />
                </div>

                <div className="flex">
                  {shipmentDraft.deliveryType === DeliveryType.AT_OFFICE && (
                    <BusinessUnitSearch
                      selected={shipmentDraft.buConsignee}
                      error={errors.location}
                      disabled={
                        !shipmentDraft.shipper ||
                        !!shipmentDraft.boxAccount ||
                        !shipmentDraft.consignee
                      }
                      setSelectedLocation={(businessUnit) => {
                        saveDraft({
                          buConsignee: businessUnit,
                          consigneeAddress: businessUnit.location,
                        });
                      }}
                    />
                  )}

                  {shipmentDraft.deliveryType === DeliveryType.AT_HOME && (
                    <AddressSearch
                      businessUnits={businessUnits}
                      selected={shipmentDraft.consigneeAddress}
                      region={AutocompleteRegion.TEALCA}
                      error={errors.location}
                      disabled={
                        !shipmentDraft.shipper || !!shipmentDraft.boxAccount
                      }
                      setSelectedLocation={(location, buConsignee) => {
                        if (!auxReference) {
                          saveDraft({
                            buConsignee,
                            consigneeAddress: location,
                          });
                        } else {
                          saveDraft({
                            buConsignee,
                            consigneeAddress: {
                              ...location,
                              reference: auxReference,
                            },
                          });
                          setAuxReference("");
                        }
                      }}
                    />
                  )}

                  {shipmentDraft.deliveryType ===
                    DeliveryType.INTERNATIONAL && (
                    <AddressSearch
                      businessUnits={businessUnits}
                      selected={shipmentDraft.consigneeAddress}
                      region={AutocompleteRegion.INTERNATIONAL}
                      error={errors.location}
                      disabled={
                        !shipmentDraft.shipper || !!shipmentDraft.boxAccount
                      }
                      setSelectedLocation={(location, buConsignee) => {
                        saveDraft({
                          buConsignee,
                          consigneeAddress: location,
                        });
                      }}
                    />
                  )}
                </div>
              </div>

              <div
                className={classNames(
                  "flex flex-1 flex-col",
                  shipmentDraft.deliveryType === DeliveryType.AT_OFFICE &&
                    "hidden"
                )}
              >
                <FormTextInput
                  value={
                    shipmentDraft.consigneeAddress?.reference ?? auxReference
                  }
                  onChange={(event) => {
                    saveDraft({
                      consigneeAddress: !!shipmentDraft.consigneeAddress
                        ? {
                            ...shipmentDraft.consigneeAddress,
                            reference: event.target.value,
                          }
                        : undefined,
                    });
                  }}
                  name="reference"
                  label="Punto de Referencia"
                  disabled={!shipmentDraft.consigneeAddress}
                />
              </div>
            </div>
          </div>

          {/* Select pay modality */}
          <div
            className={classNames(
              (!!shipmentDraft.shipper &&
                !!shipmentDraft.consigneeAddress &&
                !shipmentDraft.boxAccount &&
                shipmentDraft.shipperAuth ===
                  AccountAuthorization.NO_AUTHORIZED) ||
                shipmentDraft.shipperAuth !== AccountAuthorization.NO_AUTHORIZED
                ? "flex"
                : "hidden"
            )}
          >
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex flex-1 flex-col gap-4">
                {shipmentDraft.shipperAuth ===
                  AccountAuthorization.NO_AUTHORIZED &&
                  (!!shipmentDraft.boxAccount ||
                    !!shipmentDraft.consigneeAddress) && (
                    <FormSimpleRadioGroup
                      horizontal
                      name={`${randomId}-paymentMode`}
                      selected={shipmentDraft.paymentMode}
                      options={paymentModes}
                      label="Modalidad de pago"
                      onSelectOption={(paymentMode) => {
                        if (
                          paymentMode !== PaymentMode.CONTADO &&
                          shipmentDraft.service === ShipmentService.DOCUMENT &&
                          shipmentDraft.pieces!.length > 0
                        ) {
                          setAuxPaymentMode(paymentMode);
                          setOpenPaymentModeModal(true);
                          return;
                        }

                        handlePaymentModeChange(paymentMode);
                      }}
                      disabled={
                        disablePaymentMode ||
                        !shipmentDraft.shipper ||
                        !shipmentDraft.consigneeAddress ||
                        !!shipmentDraft.boxAccount ||
                        //!shipperIsEditable ||
                        shipmentDraft.deliveryType ===
                          DeliveryType.INTERNATIONAL ||
                        !!shipmentDraft.preShipmentHeaderID
                      }
                    />
                  )}
                {(shipmentDraft.shipperAuth ===
                  AccountAuthorization.AUTHORIZED ||
                  shipmentDraft.shipperAuth ===
                    AccountAuthorization.ENTERPRISE) &&
                  !shipmentDraft.boxAccount &&
                  !!shipmentDraft.consigneeAddress && (
                    <>
                      {paymentModesWithAuth.length <= 3 ? (
                        <FormSimpleRadioGroup<number | string>
                          label="Modalidad de pago"
                          horizontal
                          name={`${randomId}-paymentMode-with-authAccount`}
                          selected={
                            shipmentDraft.accountBillTo &&
                            shipmentDraft.paymentMode === PaymentMode.CREDIT
                              ? `${shipmentDraft.accountBillTo.id}-${shipmentDraft.accountSiteID}`
                              : shipmentDraft.paymentMode
                          }
                          options={paymentModesWithAuth.map((option) => {
                            const value = option.value;
                            if (typeof value !== "object") {
                              return {
                                name: option.name,
                                value: value,
                                disabled: option.disabled,
                                tooltip: option.tooltip,
                              };
                            } else {
                              return {
                                name: option.name,
                                value: `${value.id}-${value.accountSiteID}`,
                                disabled: option.disabled,
                                tooltip: option.tooltip,
                              };
                            }
                          })}
                          disabled={
                            disablePaymentMode ||
                            !!shipmentDraft.preShipmentHeaderID
                          }
                          onSelectOption={(option) => {
                            if (
                              option !== PaymentMode.CONTADO &&
                              shipmentDraft.service ===
                                ShipmentService.DOCUMENT &&
                              shipmentDraft.pieces!.length > 0
                            ) {
                              setAuxPaymentMode(option);
                              setOpenPaymentModeModal(true);
                              return;
                            }

                            handlePaymentModeChange(option);
                          }}
                        />
                      ) : (
                        <FormSelectOptionObjects
                          label="Modalidad de pago"
                          selected={
                            shipmentDraft.accountBillTo &&
                            shipmentDraft.paymentMode === PaymentMode.CREDIT
                              ? `${shipmentDraft.accountBillTo.id}-${shipmentDraft.accountSiteID}`
                              : shipmentDraft.paymentMode
                          }
                          name={`${randomId}-paymentMode-with-authAccount`}
                          options={paymentModesWithAuth.map((option) => {
                            const value = option.value;
                            if (typeof value !== "object") {
                              return {
                                name: option.name,
                                value: value,
                                disabled: option.disabled,
                                tooltip: option.tooltip,
                              };
                            } else {
                              return {
                                name: option.name,
                                value: `${value.id}-${value.accountSiteID}`,
                              };
                            }
                          })}
                          disabled={
                            disablePaymentMode ||
                            !!shipmentDraft.preShipmentHeaderID
                          }
                          onSelectOption={(option) => {
                            if (
                              option !== PaymentMode.CONTADO &&
                              shipmentDraft.service ===
                                ShipmentService.DOCUMENT &&
                              shipmentDraft.pieces!.length > 0
                            ) {
                              setAuxPaymentMode(option);
                              setOpenPaymentModeModal(true);
                              return;
                            }

                            handlePaymentModeChange(option);
                          }}
                          /*optionString={(selected) =>
                            paymentModesWithAuth.find(
                              (payMode) => payMode.value === selected
                            )?.name ?? ""
                          }*/
                          //optionString={(option) => option.name}
                          onSearch={(option, search) => {
                            return option.name
                              .toLowerCase()
                              .split(" ")
                              .some((s) => s.startsWith(search));
                          }}
                        />
                      )}
                    </>
                  )}
              </div>

              <div
                className={classNames(
                  shipmentDraft.paymentMode !== PaymentMode.BOXOFFICE_CREDIT &&
                    "hidden"
                )}
              >
                <p className="text-xs text-gray-600">
                  Crédito Taquilla disponible:{" "}
                  <span className="font-semibold">
                    {currencyExchangeText(
                      buCredit?.balance ?? 0,
                      exchanges,
                      "USD",
                      buCredit?.currencyID === 1 ? "BS" : "USD"
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <hr className="border-gray-300 my-6" />

          {/* Pieces */}
          <div className="flex flex-1 flex-col gap-8" ref={piecesRef}>
            <ShipmentPiecesDetails
              id={randomId}
              service={shipmentDraft.service ?? ShipmentService.STANDARD}
              typeDisabled={
                shipmentDraft.deliveryType === DeliveryType.INTERNATIONAL ||
                consigneeType === "locker"
              }
              pieces={shipmentDraft.pieces ?? []}
              pieceCategories={pieceCategories}
              paymentMode={shipmentDraft.paymentMode}
              error={errors.pieces}
              setServiceType={(type) => saveDraft({ service: type })}
              serviceOptions={availableServiceTypes}
              chargedWeight={shipmentDraft.totalChargedWeight}
              physicalWeight={shipmentDraft.totalPhysicalWeight}
              dimensionalWeight={shipmentDraft.totalDimensionalWeight}
              maxSumWeight={Math.min(
                piecesRestrictions.maxSumWeight ?? Infinity,
                allowedServices.find(
                  (service) => service.serviceID === shipmentDraft.service
                )?.maxWeight ?? Infinity
              )}
              applyMinValue={
                !shipmentDraft.boxAccount &&
                shipmentDraft.service === ShipmentService.STANDARD &&
                (shipmentDraft.paymentMode === PaymentMode.COD ||
                  shipmentDraft.paymentMode === PaymentMode.CONTADO)
              }
              setPieces={(pieces) => {
                const value =
                  parseFloat(
                    shipmentDraft.declaredValue
                      ? shipmentDraft.declaredValue
                      : "0"
                  ) / pieces.length;

                saveDraft({
                  pieces: pieces!.map((piece) => ({
                    ...piece,
                    value,
                  })),
                });
                setErrors((errors) => ({ ...errors, pieces: false }));
              }}
            />

            <div className="flex gap-4">
              <div className="w-full">
                <FormTextInput
                  value={declaredValue}
                  error={errors.declaredValue}
                  onChange={(event) => {
                    if (handleNumberChange(event)) {
                      verifyDeclaredValue(event.target.value);
                      setDeclaredValue(event.target.value);
                    }
                  }}
                  onClick={handleDeclaredValueChange}
                  name="declaredValue"
                  label="Valor Declarado en Dólares"
                />
              </div>

              <div>
                <PrimaryButton
                  className="w-32 mt-8"
                  disabled={
                    declaredValue === shipmentDraft.declaredValue || loading
                  }
                  onClick={handleDeclaredValueChange}
                >
                  Aplicar
                </PrimaryButton>
              </div>
            </div>
          </div>

          <hr className="border-gray-300 my-6" />

          {/* Observations */}
          <div className="flex flex-1 flex-col">
            <FormTextAreaInput
              rows={4}
              maxLength={192}
              value={shipmentDraft.observations ?? ""}
              onChange={(event) => {
                saveDraft({
                  observations: event.target.value,
                });
              }}
              name="observations"
              label="Observaciones"
              className="resize-none"
            />
          </div>

          <hr className="border-gray-300 my-6" />

          {/* Items */}
          <div className="relative">
            <div
              className={classNames(
                "absolute top-0 right-2",
                isLoading === 0 && "hidden"
              )}
            >
              <LoadingIcon size="1.5rem" />
            </div>

            <ShipmentItemTable
              editable
              total={shipmentDraft.total}
              items={shipmentDraft.items ?? []}
              distance={shipmentDraft.deliveryDistance}
              declaredValue={+(shipmentDraft.declaredValue ?? "0")}
              availableItems={shipmentItems.filter(
                (item) =>
                  !item.mandatory &&
                  !(shipmentDraft.items ?? []).some((i) => i.id === item.id)
              )}
              setItems={(items) => {
                saveDraft({ items: items.sort((a, b) => a.order - b.order) });
              }}
            />

            {errors.items && (
              <p className="text-red-600 mt-2 ml-2">{errors.items}</p>
            )}
          </div>

          <hr
            className={classNames(
              "border-gray-300 my-6",
              (!ADMIN_ROLES.includes(user.user?.roleName ?? "") ||
                shipmentDraft.paymentMode === PaymentMode.CREDIT ||
                shipmentDraft.boxAccount ||
                shipmentDraft.service === ShipmentService.DOCUMENT) &&
                "hidden"
            )}
          />

          {/* Discount */}
          <div
            className={classNames(
              "flex flex-col sm:items-center xl:items-start sm:gap-12 sm:flex-row",
              (!ADMIN_ROLES.includes(user.user?.roleName ?? "") ||
                shipmentDraft.paymentMode === PaymentMode.CREDIT ||
                shipmentDraft.boxAccount ||
                shipmentDraft.service === ShipmentService.DOCUMENT) &&
                "hidden"
            )}
          >
            <div className="flex flex-col xl:flex-row">
              <div>
                <FormLabel
                  fieldName={"Descuento Tienda"}
                  htmlFor="discount"
                  fieldNameClassname="mt-2 w-44"
                />
              </div>

              <div style={{ width: "17rem" }}>
                <FormTextInput
                  id="discount"
                  name="discount"
                  error={errors.discount}
                  value={shipmentDraft.discount ?? ""}
                  placeholder="Porcentaje de descuento"
                  onChange={(event) => {
                    const setValue = (value: string) => {
                      event.target.value = value;
                    };
                    if (!formatFloat(event.target.value, setValue)) return;

                    if (+event.target.value > maxDiscount) {
                      setErrors((errors) => ({
                        ...errors,
                        discount: `El descuento no puede ser mayor a ${maxDiscount}%`,
                      }));
                    } else {
                      setErrors((errors) => ({ ...errors, discount: "" }));
                    }
                    saveDraft({ discount: event.target.value });
                  }}
                />
                <p
                  className={classNames(
                    "text-sm text-gray-500 ml-2 mt-1",
                    !!errors.discount && "hidden"
                  )}
                >
                  Máximo {maxDiscount}%
                </p>
              </div>
            </div>

            <div className="w-full sm:w-auto mt-4 lg:mt-0">
              <AddButton
                disabled={!!errors.discount || !Number(shipmentDraft.discount)}
                onClick={() => {
                  const mainService = shipmentDraft.items?.find(
                    (item) => item.isItemBase
                  );
                  if (!mainService || !shipmentDraft.items) return;

                  const value =
                    Math.trunc(
                      -(shipmentDraft.discount ?? 0) * mainService.rate.value
                    ) / 100;
                  const ivaPercentage =
                    mainService.rate.iva / mainService.rate.value;
                  const ipostelPercentage =
                    mainService.rate.ipostel / mainService.rate.value;

                  // TODO: Delegate rate calculations to backend by using rate service
                  const discount = {
                    ...discountItem,
                    rate: {
                      value,
                      distance: 0,
                      tierCode: "",
                      tierName: "",
                      isPercentage: false,
                      iva: +(
                        (-(shipmentDraft.discount ?? 0) *
                          mainService.rate.value *
                          ivaPercentage) /
                        100
                      ).toFixed(2),
                      ipostel:
                        shipmentDraft.pieces &&
                        shipmentDraft.pieces.reduce((acc, piece) => {
                          return acc + piece.weight;
                        }, 0) < 30
                          ? +(
                              (-(shipmentDraft.discount ?? 0) *
                                mainService.rate.value *
                                ipostelPercentage) /
                              100
                            ).toFixed(2)
                          : 0,
                    },
                  };
                  const index = shipmentDraft.items.findIndex(
                    (item) => item.id === discount.id
                  );

                  if (index !== undefined && index >= 0) {
                    const items = [...shipmentDraft.items];
                    items[index] = discount;
                    saveDraft({ items });
                  } else {
                    const items = [...shipmentDraft.items, discount];
                    items.sort((a, b) => a.order - b.order);
                    saveDraft({ items });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </AnimatedHeight>

      {/* Delete modal */}
      <Modal openModal={openDeleteModal} setOpenModal={setOpenDeleteModal}>
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-semibold leading-7 text-gray-900">
            Eliminar guía
          </h2>

          <div className="flex flex-row gap-1">
            <p className="text-gray-500">
              ¿Está seguro que desea eliminar la guía?
            </p>
          </div>

          <div className="flex flex-row gap-4 items-center justify-between">
            <SecondaryButton
              onClick={() => {
                setOpenDeleteModal(false);
                deleteDraft();
              }}
              className="px-4 py-2"
            >
              Eliminar
            </SecondaryButton>
            <PrimaryButton onClick={() => setOpenDeleteModal(false)}>
              Cancelar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      {/* Clean modal */}
      <Modal openModal={openCleanModal} setOpenModal={setOpenCleanModal}>
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-semibold leading-7 text-gray-900">
            Limpiar guía
          </h2>

          <div className="flex flex-row gap-1">
            <p className="text-gray-500">
              ¿Está seguro que desea limpiar la guía?
            </p>
          </div>

          <div className="flex flex-row gap-4 items-center justify-between">
            <SecondaryButton onClick={() => setOpenCleanModal(false)}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setOpenCleanModal(false);
                clean();
              }}
              className="px-4 py-2"
            >
              Limpiar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      {/* Creation modal */}
      <CreateClientModal
        openModal={openCreationModal}
        setOpenModal={setOpenCreationModal}
        setSelectedItem={(client) => {
          if (clientTypeSelected === "shipper") {
            saveDraft({
              shipper: client,
              consignee: undefined,
              consigneeAddress: undefined,
              boxAccount: undefined,
              buConsignee: undefined,
              deliveryType: DeliveryType.AT_OFFICE,
            });
          } else {
            saveDraft({ consignee: client });
          }
        }}
      />

      {/* Update modal */}
      {openUpdateModal && (
        <UpdateClientModal
          openModal={openUpdateModal}
          setOpenModal={setOpenUpdateModal}
          setSelectedItem={(client) => {
            if (clientTypeSelected === "shipper") {
              saveDraft({ shipper: client });
            } else {
              saveDraft({ consignee: client });
            }
          }}
          selectedItem={
            clientTypeSelected === "shipper"
              ? shipmentDraft.shipper!
              : shipmentDraft.consignee!!
          }
        />
      )}

      {/* Load pre-shipment modal */}
      <Modal
        openModal={openPreShipmentModal}
        setOpenModal={setOpenPreShipmentModal}
      >
        <div className="flex flex-col gap-4" style={{ minWidth: "20rem" }}>
          <h2 className="text-2xl font-semibold leading-7 text-gray-900">
            Cargar Pre-Guia
          </h2>

          <div>
            <FormTextInput
              type="number"
              value={preShipmentCode}
              error={preShipmentError}
              onChange={(event) => {
                setPreShipmentError("");
                setPreShipmentCode(event.target.value);
              }}
              name="pre-shipment-code"
              label="Código de Pre-Guía"
              placeholder="Ejemplo: 0000"
            />
          </div>

          <div className="flex flex-row gap-4 items-center justify-between">
            <SecondaryButton
              onClick={() => setOpenPreShipmentModal(false)}
              className="px-4 py-2"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton type="submit" onClick={handlePreShipmentSubmit}>
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={openConsigneeTypeModal}
        setOpenModal={setOpenConsigneeTypeModal}
      >
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            Esta acción eliminará las piezas seleccionadas. ¿Desea continuar?
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenConsigneeTypeModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="px-4"
              onClick={() => {
                saveDraft({
                  pieces: [],
                  buConsignee: undefined,
                  consignee: undefined,
                  consigneeAddress: undefined,
                  boxAccount: undefined,
                  deliveryType: DeliveryType.AT_OFFICE,
                });
                setConsigneeType(auxConsigneeType);
                setOpenConsigneeTypeModal(false);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={openPaymentModeModal}
        setOpenModal={setOpenPaymentModeModal}
      >
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>

          <p className="mt-2 text-center text-gray-900">
            Esta acción eliminará las piezas seleccionadas. ¿Desea continuar?
          </p>

          <div className="mt-4 flex w-full flex-row justify-between gap-4">
            <SecondaryButton
              className="px-4"
              onClick={() => setOpenPaymentModeModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="px-4"
              onClick={async () => {
                saveDraft({ pieces: [] });
                await handlePaymentModeChange(auxPaymentMode);
                setOpenPaymentModeModal(false);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <GoogleMap
        id={`dummy-map-${randomId}`}
        mapContainerStyle={{ height: "0", width: "0" }}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      />
    </div>
  );
};

export default ShipmentForm;
