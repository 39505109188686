import React, { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { FormDatePicker, FormTextInput } from "../FormFields";
import moment from "moment";
import classNames from "classnames";
import { PrimaryButton } from "../Buttons";
import { getManifestHeader } from "../../services/manifestServices";
import {
  ManifestHeaderInterface,
  ManifestType,
} from "../../interfaces/ManifestInterface";

interface SearchManifestProps {
  manifestType: ManifestType;
  onSearch: (data: ManifestHeaderInterface[] | null) => void;
  setIsLoadingGene?: React.Dispatch<React.SetStateAction<boolean>>; // Optional isLoadingGene prop
}

const SearchManifest: React.FC<SearchManifestProps> = ({
  manifestType,
  onSearch,
  setIsLoadingGene,
}) => {
  // const userBU = useAppSelector((state) => state.user.businessUnit);
  const [manifestNumber, setManifestNumber] = useState("");
  // const [origin, setOrigin] = useState<BusinessUnitInterface | undefined>(
  //   undefined
  // );
  // const [destination, setDestination] = useState<
  //   BusinessUnitInterface | undefined
  // >(undefined);
  const [fromDate, setFromDate] = useState<Date | null>(
    moment().subtract(3, "days").toDate()
  );
  const [toDate, setToDate] = useState<Date | null>(new Date());
  // const [buSource, setBuSource] = useState(userBU);

  const handleSearchManifest = async () => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    if (setIsLoadingGene) setIsLoadingGene(true);

    const data = await getManifestHeader(
      buCode,
      fromDate || new Date(),
      toDate || new Date(),
      ManifestType.DESPACHO,
      manifestNumber || null
    );
    if (data && data.length > 0) {
      data.forEach((header) => {
        if (header.drivers && header.driversIdentificationNumber) {
          const driverNames = header.drivers.split(",");
          const driverIds = header.driversIdentificationNumber.split(",");

          if (driverNames.length === driverIds.length) {
            header.driver1Name = driverNames[0] || "";
            header.driver1IdentificationNumber = driverIds[0] || "";
            header.driver2Name = driverNames[1] || "";
            header.driver2IdentificationNumber = driverIds[1] || "";
            header.driver3Name = driverNames[2] || "";
            header.driver3IdentificationNumber = driverIds[2] || "";
          }
        }
      });
    }

    onSearch(data);

    if (setIsLoadingGene) setIsLoadingGene(false);
  };

  // const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
  //   BusinessUnitInterface | undefined
  // >(undefined);
  // const [selectedLocation, setSelectedLocation] = useState<
  //   LocationInterface | undefined
  // >(undefined);

  // Redux state
  const user = useAppSelector((state) => state.user);
  // const businessUnits = useAppSelector(
  //   (state) => state.inmutable.businessUnits
  // );

  // const handleSetLocation = (
  //   location?: LocationInterface,
  //   businessUnit?: BusinessUnitInterface
  // ) => {
  //   setSelectedLocation(location);
  //   setSelectedBusinessUnit(businessUnit);
  // };

  return (
    /* Filters */
    <div className="flex flex-row px-2 gap-4 mb-2 w-full">
      <div className="w-full sm:w-1/3">
        <label className="block text-m font-medium leading-6 text-gray-900 mb-2  truncate">
          Código de Manifiesto
        </label>
        <FormTextInput
          value={manifestNumber}
          onChange={(e) => setManifestNumber(e.target.value)}
          type="text"
          placeholder="Ingrese código de manifiesto"
        />
      </div>

      <div className="w-full sm:w-1/3">
        {/* md:w-auto"> */}
        <FormDatePicker
          id="dateFrom"
          name="date"
          label="Desde"
          useRange={false}
          maxDate={new Date()}
          value={{
            startDate: fromDate,
            endDate: fromDate,
          }}
          toggleClassName={(oldClassname) =>
            classNames(oldClassname, "text-indigo-600")
          }
          onChange={(e) =>
            setFromDate(!!e?.startDate ? moment(e.startDate).toDate() : null)
          }
          configs={{
            shortcuts: {},
          }}
        />
      </div>
      <div className="w-full sm:w-1/3">
        {/* <div className="w-full md:w-auto"> */}
        <FormDatePicker
          id="dateTo"
          name="date"
          label="Hasta"
          useRange={false}
          maxDate={new Date()}
          value={{
            startDate: toDate,
            endDate: toDate,
          }}
          toggleClassName={(oldClassname) =>
            classNames(oldClassname, "text-indigo-600")
          }
          onChange={(e) =>
            setToDate(!!e?.startDate ? moment(e.startDate).toDate() : null)
          }
          configs={{
            shortcuts: {},
          }}
        />
      </div>

      <div className="w-1/12 mt-8">
        <PrimaryButton
          className="
              px-3 py-2"
          onClick={handleSearchManifest}
          type="button"
        >
          Buscar
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SearchManifest;
