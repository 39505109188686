import { FC } from "react";
import { ManifestHeaderSingleInterface } from "../../interfaces/ManifestInterface";

const ManifestHeaderData: FC<ManifestHeaderSingleInterface> = ({
  masterShipmentHeaderID,
  masterShipmentHeaderCode,
  manifestTypeID,
  buCodeSource,
  buCodeDestination,
  creationDate,
  transport,
  driver1Name,
  statusName,
}) => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/3 px-2 mb-4">
        <p className="font-light text-xl">Origen:</p>
        <p className="font-medium text-gray-800 ">{buCodeSource}</p>
      </div>
      <div className="w-full md:w-1/3 px-2 mb-4">
        <p className="font-light text-xl">Destino:</p>
        <p className="font-medium text-gray-800 ">{buCodeDestination}</p>
      </div>
      <div className="w-full md:w-1/3 px-2 mb-4">
        <p className="font-light text-xl">Ruta:</p>
        <p className="font-medium text-gray-800 ">
          {"Plataforma de pruebaa QCP - CCS"}
        </p>
      </div>
      <div className="w-full md:w-1/3 px-2 mb-4">
        <p className="font-light text-xl">Transporte:</p>
        <p className="font-medium text-gray-800 ">{transport}</p>
      </div>
      <div className="w-full md:w-1/3 px-2 mb-4">
        <p className="font-light text-xl">Conductor:</p>
        <p className="font-medium text-gray-800 ">{driver1Name}</p>
      </div>
      <div className="w-full md:w-1/3 px-2 mb-4">
        <p className="font-light text-xl">Estatus:</p>
        <p className="font-medium text-gray-800 ">{statusName}</p>
      </div>
    </div>
  );
};

export default ManifestHeaderData;
