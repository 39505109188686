import { FC } from "react";


const Reception: FC = () => {

    return (
        <main className="lg:pl-72">
          {/* Header */}
          <div className="py-8 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
            <header className="ml-4 text-2xl font-bold text-gray-700 ">
              Recepción
            </header>
          </div>
    
                   
        </main>
      );
};

export default Reception;